import { ProcessStatus } from "@alethea-medical/alethea-components";
import { Grid } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import { useEffect, useState } from "react";
import { Control, UseFormUnregister, useWatch } from "react-hook-form";
import { Activity, ConsultFormInputField, Service } from "../../../../../shared/types";
import AddLinks from "../../../../components/AddLinks";
import { TypedFormCheckbox } from "../../../../components/FormInputFields/FormCheckbox";
import { TypedFormNumberField } from "../../../../components/FormInputFields/FormNumberField";
import { TypedFormTextArea } from "../../../../components/FormInputFields/FormTextArea";
import ConsultFormCard from "../ConsultFormCard";
import useServiceForm from "./useServiceForm";

interface ServiceFormContainerProps {
    control: Control<Activity.ConsultFormFields>,
	/** Fields passed from EMR to populate form with */
	emrFormFields: { [fieldName: string]: string }
    disabled: boolean
    unregister: UseFormUnregister<Activity.ConsultFormFields>,
	updateSymptomField: (header: string, value: string) => void,
}

export const formSafeEncode = (str: string): string => {
	return btoa(str);//encode base64 since react hook form doesn't like special characters in variable names
}

const ServiceFormContainer = ({ control, emrFormFields, unregister, updateSymptomField, disabled }: ServiceFormContainerProps) => {
	const serviceId = useWatch({ control, name: "serviceId" });

	const { serviceForm, serviceFormState, serviceFormError } = useServiceForm({ serviceId })

	/** Field names are recorded to symptomFields so they can be unregistered when the specialty/subsite changes */
	const [symptomFields, setSymptomFields] = useState<`symptoms.${string}`[]>([]);
	const [symptomsJsx, setSymptomsJsx] = useState<JSX.Element[]>([]);

	useEffect(() => {
		unregister(symptomFields);
		setSymptomFields([]);
	}, [serviceForm])

	useEffect(() => {
		if(serviceForm !== undefined) {
			for(const key in emrFormFields) {
				if(serviceForm.formFields.find((field) => field.header === key) !== undefined) {
					updateSymptomField(key, emrFormFields[key]);
				}
			}
		}
	}, [serviceForm, emrFormFields])

	useEffect(() => {
		setSymptomsJsx(generateSymptomFields(serviceForm));
	}, [serviceForm, disabled])

	const createCheckboxes = (header: string, values: string[], fieldRef: `symptoms.${string}`[]) => {
		return (
			<>
				<InputLabel style={{ whiteSpace: "pre-wrap" }}><AddLinks text={header}/></InputLabel>
				{values.map((_, i) => {
					if (i % 2 === 0 && (i + 1) < values.length) {
						return (
							<Grid container spacing={2} key={`checkbox_row_${values[i]}`}>
								{createCheckbox(header, values[i], fieldRef)}
								{createCheckbox(header, values[i + 1], fieldRef)}
							</Grid>
						);
					}
					else if (i % 2 === 0 && i === values.length - 1) {
						return (
							<Grid container spacing={2} key={`checkbox_row_${values[i]}`}>
								{createCheckbox(header, values[i], fieldRef)}
							</Grid>
						);
					}
				})}
			</>
		);
	}

	const createCheckbox = (header: string, value: string, fieldRef: `symptoms.${string}`[]) => {

		//react hook form doesn't like its input names having commas and some other special characters
		//Use uri encoding to get around this
		const fieldName = `${formSafeEncode(header)}.${formSafeEncode(value)}`;
		fieldRef.push(`symptoms.${fieldName}`)
		return (
			<Grid item xs={6}>
				<TypedFormCheckbox
					name={`symptoms.${fieldName}`}
					control={control}
					label={value}
				/>
			</Grid>
		);
	}

	const createTextField = (header: string, required: boolean | undefined, fieldRef: `symptoms.${string}`[]) => {
		const fieldName = `${formSafeEncode(header)}`;
		fieldRef.push(`symptoms.${fieldName}`)
		return (
			<Grid item xs={12} >
				<InputLabel><AddLinks text={header}/></InputLabel>
				<TypedFormTextArea
					name={`symptoms.${fieldName}`}
					control={control}
					initRows={1}
					disabled={disabled}
					rules={{required: {value: required, message: `${header} is required`}}}
				/>
			</Grid>
		);
	}

	const createNumberField = (header: string, required: boolean | undefined, fieldRef: `symptoms.${string}`[]) => {
		const fieldName = `${formSafeEncode(header)}`;
		fieldRef.push(`symptoms.${fieldName}`)
		return (
			<Grid item xs={12}>
				<InputLabel><AddLinks text={header}/></InputLabel>
				<TypedFormNumberField
					name={`symptoms.${fieldName}`}
					control={control}
					rules={{required: {value: required, message: `${header} is required`}}}
				/>
			</Grid>
		);
	}

	const generateSymptomFields = (serviceForm: Service.ServiceForm | undefined) => {
		let symptomsJsx: JSX.Element[] = [];
		const symptomFieldNames: `symptoms.${string}`[] = [];
		if (serviceForm !== undefined) {
			symptomsJsx = serviceForm.formFields.map((symptom: ConsultFormInputField) => {
				let jsx: JSX.Element = <></>;
				switch (symptom.inputType) {
					case "Checkboxes":
						if (symptom?.header && symptom?.values) {
							jsx = createCheckboxes(symptom.header, symptom.values, symptomFieldNames);
						}
						break;
					case "Textfield":
						if (symptom?.header) {
							jsx = createTextField(symptom.header, symptom.required, symptomFieldNames);
						}
						break;
					case "Number":
						if (symptom?.header) {
							jsx = createNumberField(symptom.header, symptom.required, symptomFieldNames);
						}
						break;
				}
				return (
					<Grid item xs={12} key={`card_${serviceId}_${symptom.header}`}>
						<ConsultFormCard>
							<Grid item xs={12}>
								{jsx}
							</Grid>
						</ConsultFormCard>
					</Grid>
				)
			});
		}
		setSymptomFields(symptomFieldNames);
		return symptomsJsx;
	}

	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<ProcessStatus state={serviceFormState} errorMessage={serviceFormError}/>
				</Grid>
				{symptomsJsx}
			</Grid>
		</>
	);
}
export default ServiceFormContainer;