import { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Control, useWatch } from 'react-hook-form';
import RoundedButton from '../../../../../components/RoundedButton';
import Alert from '@material-ui/lab/Alert';
import strings from '../../Common/strings';
import InformationBox from '../Container/InformationBox';
import FormattedInputLabel from '../../../../../components/FormattedInputLabel';
import { TypedFormTextField } from '../../../../../components/FormInputFields/FormTextField';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { fbFunctions, logAnalyticsEvent } from '../../../../../firebase';
import { ProcessState, ProcessStatus, useProcessState } from '@alethea-medical/alethea-components';
import HelpModal from '../../../../../components/HelpModal';
import CheckIcon from "@material-ui/icons/Check";
import InputAdornment from '@material-ui/core/InputAdornment';
import useCms from '../../../../../utils/useCms';
import { SignupInfo } from '@alethea-medical/aletheamd-types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonMargin: {
            marginBottom: "4px"
        }
    })
)

interface ValidationSettings {
    validationEnabled: boolean,
    fieldName: string,
}


interface PracIdValidationProps {
    control: Control<SignupInfo.SignupInfo>,
    isPracIdValid: boolean,
    setIsPracIdValid: (value: boolean) => void,
    proceedWithoutPracId: boolean,
    setProceedWithoutPracId: (value: boolean) => void,
    disabled: boolean
}

const PracIdValidation = ({ control, isPracIdValid, setIsPracIdValid, proceedWithoutPracId, setProceedWithoutPracId, disabled }: PracIdValidationProps) => {
    const classes = useStyles();
    
    const cmsSignup = useCms({cmsId: "signup"})

    const province = useWatch({ control, name: "province", defaultValue: "" })
    const pracId = useWatch({control, name: "practiceId", defaultValue: ""})
    
    // Default to no validation and Practice ID as field name if Province not found in CMS
    const [validationSettings, setValidationSettings] = useState<ValidationSettings>({ validationEnabled: false, fieldName: "Practice ID" })
    const [showInvalidMessage, setShowInvalidMessage] = useState(false);
    const [showProceedWithoutValidationButton, setShowProceedWithoutValidationButton] = useState(false);

    const validatePracId = fbFunctions.httpsCallable( "signup-validatePracId" );

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });

    useEffect(() => {
        if(province !== "" && cmsSignup.isLoaded())
        {
            const newSettings = cmsSignup.getValue<{[province: string]: ValidationSettings}>(`validationSettings`)
            if(newSettings?.[province] !== undefined) {
                setValidationSettings(newSettings[province])
            }
        }

    }, [province, cmsSignup])

    useEffect(() => {
        setProcessState(ProcessState.idle)
        if(validationSettings.validationEnabled !== true) {
            setProceedWithoutPracId(true)
            setIsPracIdValid(false)
            setShowInvalidMessage(false);
            setShowProceedWithoutValidationButton(false);
        }
        else {
            setProceedWithoutPracId(false);
            setIsPracIdValid(false);
            setShowInvalidMessage(false);
            setShowProceedWithoutValidationButton(false);
        }
    }, [validationSettings])

    //Reset values when changing prac ID
    useEffect(() => {
        if(validationSettings.validationEnabled) {
            setProceedWithoutPracId(false);
            setIsPracIdValid(false);
        }
    }, [pracId, validationSettings])


    const handleValidatePracId = () => {
        setProceedWithoutPracId(false);
        setIsPracIdValid(false);
        setShowInvalidMessage(false);
        setShowProceedWithoutValidationButton(false);

        setProcessState(ProcessState.running)

        validatePracId({practiceId: pracId, province: province }).then((result) => {
            if(result.data.validated) {
                logAnalyticsEvent("signup_prac_id_validation_success")
                setIsPracIdValid(true);
            }
            else {
                logAnalyticsEvent("signup_prac_id_validation_fail")
                setIsPracIdValid(false);

                setShowInvalidMessage(true);
                setShowProceedWithoutValidationButton(true);
            }
            setProcessState(ProcessState.idle)
        })
        .catch((error: Error) => {
            setIsPracIdValid(false);
            setShowProceedWithoutValidationButton(true);

            errorHandler({
                error: error,
                userMessage: strings.systemErrorValidatingPracId(validationSettings.fieldName),
                hideErrorMessage: true,
                analyticsLog: `signup_prac_id_validation_fail`
            })
        })
    }

    const handleProceedWithoutPracId = () => {
        setProcessState(ProcessState.idle);
                
        setProceedWithoutPracId(true);
        setIsPracIdValid(false);

        setShowInvalidMessage(false);

        logAnalyticsEvent("signup_prac_id_continue_without_validate")
    }
    
    const isDisabledValidating = () => {
        return processState === ProcessState.running
    }


    return (
        <>
            <Grid container alignItems='center' spacing={2}>
                <Grid item xs={12} md={true}>
                    <Grid container spacing={2} alignItems='center'>
                        <Grid item xs={11}>
                            <FormattedInputLabel>{validationSettings.fieldName}</FormattedInputLabel>
                            <TypedFormTextField
                                control={control}
                                name="practiceId"
                                disabled={isDisabledValidating() || disabled}
                                errorTextPadding
                                {...{
                                    InputProps: {
                                        endAdornment: <>{isPracIdValid && <InputAdornment position='end'><CheckIcon color="primary"/></InputAdornment>}</>
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <HelpModal helpText={[strings.pracIdNote(validationSettings.fieldName)]}/>
                        </Grid>
                    </Grid>
                </Grid>
                {validationSettings.validationEnabled &&
                    <>
                        <Grid item xs={12} md={4}>
                            <RoundedButton className={classes.buttonMargin} onClick={handleValidatePracId} fullWidth disabled={isDisabledValidating() || disabled}>Validate {validationSettings.fieldName}</RoundedButton>
                        </Grid>
                        <Grid item xs={12}>
                            <ProcessStatus state={processState} errorMessage={processErrorMessage}/>
                        </Grid>
                        {showInvalidMessage && 
                            <Grid item xs={12}>
                                <Alert severity="error">{strings.invalidPracIdNote(validationSettings.fieldName)}</Alert>
                            </Grid>
                        }
                        {showProceedWithoutValidationButton && (
                            <>
                                <Grid item xs={12} md={8}>
                                    <InformationBox>{strings.proceedWithoutPracIdNote(validationSettings.fieldName)}</InformationBox>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <RoundedButton className={classes.buttonMargin} onClick={handleProceedWithoutPracId} disabled={isPracIdValid || proceedWithoutPracId || isDisabledValidating() || disabled} fullWidth>Proceed without {validationSettings.fieldName}</RoundedButton>
                                </Grid>
                            </>
                        )}
                    </>
                }
                {!validationSettings.validationEnabled &&
                    <Grid item xs={12}>
                        <InformationBox>{strings.proceedWithoutPracIdNoteNoValidation(validationSettings.fieldName)}</InformationBox>
                    </Grid>
                }
            </Grid>
        </>
    );
}

export default PracIdValidation;