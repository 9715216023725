import React, { createContext, useContext, useEffect, useState } from "react";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import usePermissions from "src/components/usePermissions";

export interface PermissionsObject {
  specialty: string;
  userHasPermission: Promise<boolean>;
}

interface PermissionsContextType {
  permissions: PermissionsObject[];
  loading: boolean;
}

const PermissionsContext = createContext<PermissionsContextType | undefined>(
  undefined,
);

export const PermissionsProvider: React.FC = ({ children }) => {
  const [permissions, setPermissions] = useState<PermissionsObject[]>([]);
  const [loading, setLoading] = useState(true);

  const { granted: hasMdRoutingPermissions } = usePermissions({
    resourceKey: resourceKeys.mdRouting,
  });
  const { granted: hasDentalRoutingPermissions } = usePermissions({
    resourceKey: resourceKeys.dentalRouting,
  });
  const { granted: hasOphthalmologyRoutingPermissions } = usePermissions({
    resourceKey: resourceKeys.ophthalmologyRouting,
  });
  const { granted: hasLongTermCareRoutingPermissions } = usePermissions({
    resourceKey: resourceKeys.longTermCareRouting,
  });

  useEffect(() => {
    const loadPermissions = async () => {
      const permissions = [
        {
          specialty: "All Standard Specialties",
          userHasPermission: Promise.resolve(hasMdRoutingPermissions),
        },
        {
          specialty: "Dentistry",
          userHasPermission: Promise.resolve(hasDentalRoutingPermissions),
        },
        {
          specialty: "Ophthalmology (Pilot)",
          userHasPermission: Promise.resolve(
            hasOphthalmologyRoutingPermissions,
          ),
        },
        {
          specialty: "Long Term Care",
          userHasPermission: Promise.resolve(hasLongTermCareRoutingPermissions),
        },
      ];
      setPermissions(permissions);
      setLoading(false);
    };

    loadPermissions();
  }, [
    hasMdRoutingPermissions,
    hasDentalRoutingPermissions,
    hasOphthalmologyRoutingPermissions,
    hasLongTermCareRoutingPermissions,
  ]);

  return (
    <PermissionsContext.Provider value={{ permissions, loading }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissionsContext = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error(
      "usePermissionsContext must be used within a PermissionsProvider",
    );
  }
  return context;
};
