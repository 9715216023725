import { useCallback, useContext, useEffect, useState } from 'react';
import firebase from "firebase";
import { useProcessState, ProcessState } from '@alethea-medical/alethea-components';
import { fbFirestore, logAnalyticsEvent } from '../../../../../firebase';
import { AuthContext } from '../../../../../AuthProvider';
import { Activity } from '../../../../../../shared/types';
import analyticsLogs from '../../../../../analyticsLogs';
import { dbNames } from '@alethea-medical/aletheamd-db-keys';
import ListItemSelectController from './ListItemSelectController';
import devConsole from '../../../../../models/devConsole';
import useQueryParamRouting from '../../../../../components/useQueryParamRouting/useQueryParamRouting';
import InboxModel from '../Models/InboxModel';
import { ActivityDict, ActivityItem } from '../../types';


const loadMoreAmount = 10;

interface InboxControllerProps<MetadataType> {
    inboxModel: InboxModel,
    metadataFieldToSortBy: keyof MetadataType, 
    folder?: Activity.UserActivityFolder,
    statuses?: string[],
    setShowTabs?: (show: boolean) => void
}

function InboxController<MetadataType> ({ inboxModel, metadataFieldToSortBy, statuses, folder, setShowTabs }: InboxControllerProps<MetadataType>) {
    
    const authContext = useContext(AuthContext)
    
    const [disableLoadMore, setDisableLoadMore] = useState<boolean>(false);


    // List
    const [newActivityQueue, setNewActivityQueue] = useState<{items: ActivityItem<MetadataType>[], dontUpdateOldest?: boolean}>({ items: [] })
    const [activities, setActivities] = useState<ActivityDict<MetadataType>>({});
    const [mostRecentFetchTime, setMostRecentFetchTime] = useState<firebase.firestore.Timestamp>(firebase.firestore.Timestamp.now());
    const [oldestActivityTime, setOldestActivityTime] = useState<firebase.firestore.Timestamp>(firebase.firestore.Timestamp.now());



// #region Select Activities
    const {
        selectedItems: selectedActivities, allSelected,
        selectAllHandler, listItemSelectHandler: activitySelectHandler, unselectAllHandler 
    } = ListItemSelectController({ listItemDict: activities })
// #endregion Select Activities


    const [enableSearch, setEnableSearch] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useState<{params: string[], status: string}>({ params: [], status: ""});

	const {processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });



//#region Handling new activities
    // Handle new activities coming in and add to activities dict. Function when activities updates
    const newActivityQueueHandler = (newItems: ActivityItem<MetadataType>[], dontUpdateOldest?: boolean) => {
		if(newItems.length > 0) {
            // Update activities dictionary
            const newActivities = {...activities};

            newItems.forEach((item) => {
                if(!dontUpdateOldest) {
                    const timestamp = item.metadataActivity[metadataFieldToSortBy] as unknown as firebase.firestore.Timestamp;
                    if(timestamp < oldestActivityTime)
                        setOldestActivityTime(timestamp);
                }

                devConsole.log(`${newActivities[item.id] !== undefined ? "Update" : "New"} activity: ${item.id}. ${item.sharedActivity.recentMessage.readBy.includes(authContext.uid) ? "Read" : "Unread"}`)
                newActivities[item.id] = item
            })		
            setActivities(newActivities);
			
            //Update last fetched time, so that we only fetch new messages later than this time
            //This reduces the number of calls to this function
            setMostRecentFetchTime(firebase.firestore.Timestamp.now());
		}
    }

    // When activities are added to the queue, add them to the activity dictionary
    // A queue is used instead of adding directly to the dictionary, so that any time new activities are received, it will always use the most up to date version of the dictionary
    // This avoid stupid react bugs like using an out-dated version of activities because setActivities was called after the original function call
    useEffect(() => {
        newActivityQueueHandler(newActivityQueue.items, newActivityQueue.dontUpdateOldest)
    }, [newActivityQueue])

    /**
     * Parse new user activities coming in as firestore snapshot, fetch activity document, and add to activities dict
     * @param docs Documents from snapshot
     */
    const newUserActivitiesSnapshotHandler = (docs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[] | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>[]): Promise<void> => {
        return Promise.all(docs.map((metadataDoc) => {
            // Foreach user activity, fetch the shared activity document
            return fbFirestore.collection(dbNames.activities).doc(metadataDoc.id).get().then((sharedActivityDoc) => {
                return {
                    id: metadataDoc.id,
                    sharedActivity: sharedActivityDoc.data() as Activity.Activity,
                    metadataActivity: metadataDoc.data() as MetadataType
                }        
            })
        }))
        .then((result) => {
            setNewActivityQueue({ items: result})
        })
    }
//#endregion

//#region Loading activities
    const loadActivities = (fetchEarlierThan: firebase.firestore.Timestamp, fetchOverdue?: boolean) => {
        setProcessState(ProcessState.running);
        setDisableLoadMore(true);

        return inboxModel.loadActivities(fetchEarlierThan, { statuses, folder, amountToLoad: loadMoreAmount, fetchOverdue })
        .then(({ results, didReturnResults }) => {
            if(!didReturnResults)
                setDisableLoadMore(true);

            return newUserActivitiesSnapshotHandler(results)
            .then(() => {
                setProcessState(ProcessState.idle);
                return didReturnResults
            })

        })
        .catch((error: Error) => {
			errorHandler({
				error: error, 
				userMessage: "Error loading messages"
			});
            return false
		})
		.then((didReturnResults) => {
			setDisableLoadMore(!didReturnResults);
		})
    }

    
	//Load more activities, with timestamp less than the oldest message we currently have
	const loadMoreHandler = () => {
        if(enableSearch) {
            runSearch(searchParams.params, searchParams.status)
        }
        else {
            loadActivities(oldestActivityTime);
        }
	}

    // Reload activities when tab changes (or on first load)
	useEffect(() => {        
        resetActivities();
        loadActivities(firebase.firestore.Timestamp.now(), true)
	}, [folder, JSON.stringify(statuses)]);
        
    // Listen for new messages and put into newActivityQueue
	useEffect(() => {
        if(enableSearch)
            return;
        if(authContext.uid === "")
            return;

        // Subscribe to new activities where lastMessageReceivedAt flag is newer than the time since we last fetched
        const unsubscribe = inboxModel.subscribeToActivityMetadata(mostRecentFetchTime, newUserActivitiesSnapshotHandler, { folder })

        //Call unsubscribe to cleanup previous render
        return () => {
            unsubscribe(); 
        };

    }, [enableSearch, authContext.uid, folder, mostRecentFetchTime])

	const resetActivities = () => {
		setActivities({});
		unselectAllHandler();
        setOldestActivityTime(firebase.firestore.Timestamp.now())
	}

    //Updates activity in state by adding them to new queue. Oldest activity timestamp will not be updated
    // When updating, only update with changes. Use previous sharedActivity or metadataActivity if not provided
    // If not provided, and no previous value exists for either sharedActivity or metadataActivity, then the activity will not be added to the queue
    const updateActivitiesInState = (newActivities: {
        id: string,
        sharedActivity?: Activity.Activity,
        metadataActivity?: MetadataType
    }[]) => {

        const newActivityToQueue = newActivities.map((a) => {
            return {
                id: a.id,
                sharedActivity: a.sharedActivity ?? activities[a.id]?.sharedActivity,
                metadataActivity: a.metadataActivity ?? activities[a.id]?.metadataActivity
            }
        }).filter((a) => a.sharedActivity !== undefined && a.metadataActivity !== undefined)

        if(newActivityToQueue.length === 0)
            return;
        
        setNewActivityQueue({ items: newActivityToQueue, dontUpdateOldest: true })
    }

    const removeActivitiesFromState = (activityIds: string[]) => {
        const newActivities = {...activities}
        // Remove activities from list that have been moved
        activityIds.forEach((activityId) => {
            delete newActivities[activityId]
        })
        setActivities(newActivities);
    }
//#endregion


// #region Open Activity



    // Open activity
    // Add query parameters to url to open activity
    const { addOrRemoveFromQueryParams, currentValue: openActivityId } = useQueryParamRouting({ paramName: "econsultId"})
    
    const openActivityHandler = (activityId: string) => {
        addOrRemoveFromQueryParams(activityId)
    }
    // Hide inbox/archive tab changer when activity is open
    useEffect(() => {
        if(setShowTabs !== undefined && !enableSearch) {
            setShowTabs(openActivityId === undefined)
        }
    }, [openActivityId])

// #endregion Open Activity

// #region Search

    const runSearch = (params: string[], status: string, initialSearch?: boolean) => {
        if(params.length === 0 && status === "")
            return;

        setProcessState(ProcessState.running);
        
        // Next time user presses load More, then run search instead using saved parameters
        if(initialSearch) {
            setSearchParams({ params, status })
            logAnalyticsEvent(analyticsLogs.secureMessaging.search);
            resetActivities();
        }
        const fetchBefore = initialSearch ? firebase.firestore.Timestamp.now() : oldestActivityTime;

        setDisableLoadMore(true)
        inboxModel.searchActivities(params, status, fetchBefore, 25)
		.then(({ results, didReturnResults }) => {            
            return newUserActivitiesSnapshotHandler(results)
            .then(() => {
                if(initialSearch) {
                    setEnableSearch(true);
                    if(setShowTabs !== undefined)
                        setShowTabs(false)
                }   
                setProcessState(ProcessState.idle);
                return didReturnResults
            })
		})
		.catch((error: Error) => {
			errorHandler({
				error: error, 
				userMessage: `Error running search`
			});
            return false
		})    
        .then((didReturnResults: boolean) => {
            setDisableLoadMore(!didReturnResults);
		})
    }


    const clearSearch = () => {
        setSearchParams({ params: [], status: "" });
		setEnableSearch(false);
        if(setShowTabs !== undefined)
            setShowTabs(true)
		resetActivities();
        loadActivities(firebase.firestore.Timestamp.now(), true);
	}
    
// #endregion Search

// #region Sorting
    const [sortedActivities, setSortedActivities] = useState<ActivityItem<MetadataType>[]>([]);

    /**
     * Sort function to sort by recent message from newest to oldest
     */
    const sortOnRecentMessage = (a: ActivityItem<MetadataType>, b: ActivityItem<MetadataType>) => {
        return b.sharedActivity.recentMessage.sentAt.toMillis() - a.sharedActivity.recentMessage.sentAt.toMillis()
    }

    /**
     * Sort activities by overdue first, and then by recent message
     */
    const sortOnOverdueAndRecentMessage = useCallback((a: ActivityItem<MetadataType>, b: ActivityItem<MetadataType>) => {
        // Case as UserActivity to check overdue. Will be undefined for clinic activity which is fine for this comparison
        const aOverdue = (a.metadataActivity as unknown as Activity.UserActivity).overdue;
        const bOverdue = (b.metadataActivity as unknown as Activity.UserActivity).overdue; 
        if(aOverdue && !bOverdue) {
            return -1
        }
        else if (!aOverdue && bOverdue) {
            return 1
        }
        else {
            //If both include, or both don't include, use recent message to compare
            return sortOnRecentMessage(a, b);
        }
    }, [])

    //Sort activities and store in state
    useEffect(() => {
        setSortedActivities(Object.values(activities).sort(sortOnOverdueAndRecentMessage))
    }, [activities])
// #endregion Sort


    return {
        // State
        sortedActivities, activities,
        updateActivitiesInState,
        removeActivitiesFromState,
        loadMoreHandler,
        disableLoadMoreLoading: disableLoadMore && processState === ProcessState.running, 
        disableLoadMoreEndOfResults: disableLoadMore && processState !== ProcessState.running,
        
        isActivityOpen: openActivityId !== undefined, openActivityHandler,

        // Select
        selectedActivities, allSelected,
        selectAllHandler, activitySelectHandler,
        unselectAllHandler,

        // Search
        enableSearch,
        runSearch, clearSearch,

        //  Process state
        processState, setProcessState, processErrorMessage, errorHandler
    }
}

export default InboxController;