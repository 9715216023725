import ServiceDirectoryController, {
  ServiceDirectoryControllerProps,
} from "./ServiceDirectoryController";
import MenuItem from "@material-ui/core/MenuItem";
import {
  ServiceItem,
  getFormattedClinicCityProvinceFromService,
  getLatestCurrentVacationEndDate,
  getTitleAndCredsFromServiceUser,
  isServiceOnVacation,
} from "../../../../../db/Service";
import useConsultFormStyles from "../../useConsultFormStyles";
import {
  ProcessState,
  ProcessStatus,
} from "@alethea-medical/alethea-components";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormSelectV2 from "../../../../../components/FormInputFieldsV2/FormSelectV2";
import ResponseTime from "../../SpecialtyDropdown/ResponseTime";
import Grid from "@material-ui/core/Grid";
import palette from "../../../../../palette";
import AcceptanceFlags from "../AcceptanceFlags/AcceptanceFlags";
import isMobileDevice from "src/models/isMobileDevice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdownItem: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    dropdownMobileView: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    emptyMenuItem: {
      color: theme.palette.text.disabled,
    },
    primaryInfo: {
      whiteSpace: "normal",
    },
    vacationText: {
      textAlign: "right",
      color: palette.orange,
      fontWeight: "bold",
    },
    additionalInfo: {
      display: "flex",
      justifyContent: "flex-end",
      gap: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-end",
      },
    },
    acceptanceFlags: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        flexDirection: "row",
        justifyContent: "start",
        marginRight: 0,
        marginBottom: -theme.spacing(0.1),
        gap: theme.spacing(2),
      },
    },
  }),
);

function noSpecialistAvailable(
  selectedSubsite: string,
  searchResults: ServiceItem[],
  searchProcessState: ProcessState,
): boolean {
  return (
    selectedSubsite !== "" &&
    searchResults.length === 0 &&
    searchProcessState === ProcessState.idle
  );
}

function getSpecialistEmptyMenu(
  selectedSubsite: string,
  searchResults: ServiceItem[],
  searchProcessState: ProcessState,
) {
  if (selectedSubsite === "") {
    return <MenuItem value={""}>Select Subsite First</MenuItem>;
  } else if (
    searchResults.length === 0 &&
    searchProcessState === ProcessState.idle
  ) {
    return <MenuItem value={""}>No Specialists Available</MenuItem>;
  } else if (searchProcessState === ProcessState.running) {
    return <MenuItem value={""}>Loading Specialists...</MenuItem>;
  } else if (searchProcessState === ProcessState.error) {
    return <MenuItem value={""}>Error Loading Specialists</MenuItem>;
  } else if (searchResults.length > 0) {
    return <MenuItem value={""}>Select Specialist</MenuItem>;
  }
  return null;
}

function getSubsiteEmptyMenu(
  selectedSpecialty: string,
  specialties: { [specialty: string]: { subsites: string[] } },
) {
  if (selectedSpecialty === "") {
    return <MenuItem value={""}>Select Specialty First</MenuItem>;
  } else if (specialties[selectedSpecialty]?.subsites?.length === 0) {
    return <MenuItem value={""}>No Subsites Available</MenuItem>;
  } else if (specialties[selectedSpecialty]?.subsites?.length > 0) {
    return <MenuItem value={""}>Select Subsite</MenuItem>;
  }
  return null;
}

function renderSpecialistDropdownItem(classes: any, service: ServiceItem) {
  const serviceUser = service.service.users.find(
    (user) => user.uid === service.service.serviceOwnerUid,
  );
  const isOnVacation = isServiceOnVacation(service.service);
  const vacationEndDate = getLatestCurrentVacationEndDate(service.service);
  const titleAndCreds = getTitleAndCredsFromServiceUser(serviceUser);

  const additionalInfo = () => {
    return (
      <Grid item className={classes.additionalInfo}>
        <div className={classes.acceptanceFlags}>
          <AcceptanceFlags
            acceptanceFlags={service.service.acceptanceFlags}
            size="2.5em"
            mobileSize="1em"
          />
        </div>
        {ResponseTime({
          responseTimeMillis: service.service.econsultResponseTime,
          fontSize: "small",
        })}
      </Grid>
    );
  };

  return (
    <Grid container alignItems="center" className={classes.dropdownItem}>
      <Grid item className={classes.dropdownMobileView}>
        <div className={classes.primaryInfo}>
          {serviceUser?.userName} -{" "}
          {getFormattedClinicCityProvinceFromService(service.service)}
        </div>
        <div style={{ whiteSpace: "normal" }}>
          {titleAndCreds}
          {isOnVacation &&
            (isMobileDevice() ? (
              <Grid item className={classes.vacationText}>
                On Vacation{" "}
                {vacationEndDate &&
                  `- Return date: ${vacationEndDate.toDate().toDateString()}`}
              </Grid>
            ) : (
              <span className={classes.vacationText}>
                {" "}
                On Vacation{" "}
                {vacationEndDate &&
                  `- Return date: ${vacationEndDate.toDate().toDateString()}`}
              </span>
            ))}
        </div>
        {isMobileDevice() && additionalInfo()}
      </Grid>
      {!isMobileDevice() && additionalInfo()}
    </Grid>
  );
}

const ServiceDirectory = (props: ServiceDirectoryControllerProps) => {
  const consultFormclasses = useConsultFormStyles();
  const classes = useStyles();

  const {
    control,
    searchProcessState,
    searchResults,
    selectedService,
    specialties,
    selectedSpecialty,
    selectedSubsite,
    handleSelectSpecialty,
    handleSelectSubsite,
    handleSelectSpecialist,
    isDisabled,
    processState,
    processErrorMessage,
  } = ServiceDirectoryController(props);

  return (
    <div className={consultFormclasses.spacing}>
      <div className={consultFormclasses.spacing}>
        <ProcessStatus
          state={processState}
          errorMessage={processErrorMessage}
          loadingMessage="Loading Specialties..."
        />
      </div>
      <div className={consultFormclasses.spacing}>
        <FormSelectV2
          controlProps={{
            control: control,
            name: "specialty",
          }}
          inputFieldProps={{
            label: "Specialty",
            disabled: isDisabled,
            SelectProps: {
              displayEmpty: true,
              onChange: handleSelectSpecialty,
            },
            //Force the label to always shrink to the top so that it doesn't overlap the empty value
            InputLabelProps: {
              shrink: true,
            },
          }}
        >
          <MenuItem value={""} className={classes.emptyMenuItem}>
            Select Specialty
          </MenuItem>
          {Object.keys(specialties).map((specialty: string) => {
            return (
              <MenuItem key={`specialty-item-${specialty}`} value={specialty}>
                {specialty}
              </MenuItem>
            );
          })}
        </FormSelectV2>
      </div>
      <div className={consultFormclasses.spacing}>
        <FormSelectV2
          controlProps={{
            control: control,
            name: "subsite",
          }}
          inputFieldProps={{
            label: "Subsite",
            disabled: isDisabled || selectedSpecialty === "",
            SelectProps: {
              displayEmpty: true,
              onChange: handleSelectSubsite,
            },
            //Force the label to always shrink to the top so that it doesn't overlap the empty value
            InputLabelProps: {
              shrink: true,
            },
          }}
        >
          {getSubsiteEmptyMenu(selectedSpecialty, specialties)}
          {(specialties[selectedSpecialty]
            ? specialties[selectedSpecialty].subsites
            : []
          ).map((subsite: string) => {
            return (
              <MenuItem key={`subsite-item-${subsite}`} value={subsite}>
                {subsite}
              </MenuItem>
            );
          })}
        </FormSelectV2>
      </div>
      <div className={consultFormclasses.spacing}>
        <FormSelectV2
          controlProps={{
            control: control,
            name: "serviceId",
            rules: {
              required: { value: true, message: "A specialist is required" },
              validate: {
                isOnVacation: () => {
                  if (!selectedService) return undefined;
                  return isServiceOnVacation(selectedService.service)
                    ? "Specialist is on vacation, please save draft and wait for return or select another specialist."
                    : undefined;
                }, // return message if true, undefined if false
              },
            },
          }}
          inputFieldProps={{
            label: "Specialist",
            disabled:
              isDisabled ||
              selectedSubsite === "" ||
              noSpecialistAvailable(
                selectedSubsite,
                searchResults,
                searchProcessState,
              ),
            SelectProps: {
              displayEmpty: true,
              onChange: handleSelectSpecialist,
            },
            //Force the label to always shrink to the top so that it doesn't overlap the empty value
            InputLabelProps: {
              shrink: true,
            },
          }}
        >
          {getSpecialistEmptyMenu(
            selectedSubsite,
            searchResults,
            searchProcessState,
          )}
          {searchResults.map((service: ServiceItem) => {
            return (
              <MenuItem
                key={`service-item-${service.id}`}
                value={service.id}
                style={{ borderTop: "1px solid rgba(0, 0, 0, 0.08)" }}
              >
                {renderSpecialistDropdownItem(classes, service)}
              </MenuItem>
            );
          })}
        </FormSelectV2>
      </div>
    </div>
  );
};

export default ServiceDirectory;
