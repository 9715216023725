import React from "react";
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { InputLabel } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thread: {       
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            borderColor: theme.palette.secondary.main,
            borderWidth: theme.spacing(0.5)
        },
        padding: 0,
        margin: 0
    },
    threadText: {   
        padding: theme.spacing(1),
        margin: 0,
        overflowX: "hidden",
        whiteSpace: "pre-line",
        wordWrap: 'break-word',//break lines on word to fit
    },
    unreadText: {
        color: theme.palette.text.primary,
        fontWeight: 500,
    },
    readText: {
        color: theme.palette.text.secondary,
    },
    readThread: {
        background: theme.palette.grey[200],
    },
    unreadThread: {
        //white
    },
    unreadIcon: {
        marginRight: theme.spacing(2)
    },
    selectedThread: {
        background: theme.palette.secondary.light
    },
    checkbox: {
        height: "100%",
        margin: theme.spacing(0),
        padding: theme.spacing(0, 1)
    },
    childrenContainer: {
        marginRight: theme.spacing(1)
    }
  }),
);


interface MessageListItemProps {
    selected?: boolean,
    setSelected?: (id: string, checked: boolean) => void,
    openMessage: (id: string) => void,
    id: string,
    read?: boolean,
    primary: React.ReactChild,
    secondary?: React.ReactChild,
    /** Component to render to the right of the checkbox */
    labelComponent?: React.ReactChild,
    className?: string
}

const MessageListItem: React.FC<MessageListItemProps> = ({ selected, setSelected, openMessage, id, read, primary, secondary, labelComponent, className, children}) => {
    const classes = useStyles();


    const changeMessage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        openMessage(id);
    }

    return (
        <ListItem      
            className={clsx(classes.thread, className, {
                [classes.selectedThread]: selected,
                [classes.readThread]: !selected && read,
                [classes.unreadThread]: !selected && !read
            })} 
            button 
            divider
        >
            {setSelected !== undefined && 
                <InputLabel className={classes.checkbox}>
                    <Checkbox checked={selected} onChange={(e) => { e.stopPropagation(); setSelected(id, e.target.checked) }}/>
                </InputLabel>
            }
            {labelComponent}
            <ListItemText
                onClick={changeMessage}
                className={`${classes.threadText}`}
                primary={primary}
                primaryTypographyProps={{
                    className: `${read ? classes.readText : classes.unreadText}`
                }}
                secondary={secondary}
                secondaryTypographyProps={{
                    component:"span"
                }}
            />
            <div onClick={changeMessage} className={classes.childrenContainer}>
                {children}
            </div>
        </ListItem>
    );
}

export default MessageListItem;