import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import firebase from "firebase";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  useFormState,
} from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Activity,
  PatientInfo,
  Province,
  UserProfile,
} from "../../../../shared/types";
import { AuthContext } from "../../../AuthProvider";
import analyticsLogs from "../../../analyticsLogs";
import SelectGalleryController from "../../../components/Gallery/Controllers/SelectGalleryController";
import useFileDrop from "../../../components/useFileDrop";
import { ServiceItem } from "../../../db/Service";
import { logAnalyticsEvent } from "../../../firebase";
import RequestAttachmentHandler from "../../../models/RequestAttachmentHandler";
import { formatDateAsYYYYMMDD } from "../../../models/formatTime";
import { getActivityName, submitConsult } from "./ConsultFormFunctions";
import useDraftId from "./Draft/useDraftId";
import { formSafeEncode } from "./ServiceForm/ServiceFormContainer";
import hasPermissions from "src/models/hasPermissions";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import {
  discardDraft,
  loadDraft,
  saveOrUpdateDraft,
} from "./Draft/ConsultDraftFunctions";
import { parse } from "query-string";

export interface PatientInfoWithFormattedDoB extends PatientInfo {
  /** Date of birth, in string format YYYY/MM/DD
   * Use if dateOfBirth is already formatted this way when passing to handlePatientInfo
   */
  dateOfBirthYYYYMMDD?: string;
}

/**
 * Return default values, or prefilled with some of previously submitted form's data (such as specialty or location index)
 * @param data Data to pre-fill defaults from
 * @param profile User's profile to load province data
 * @returns New form fields object
 */
const getConsultFormFields = (
  data?: Partial<Activity.ConsultFormFields>,
  profile?: UserProfile,
): Activity.ConsultFormFields => {
  return {
    activityType: "econsult",
    searchType: data?.searchType ?? "directory",
    avaData: undefined, // Should be reset to prevent a new consult from overwriting an old one on Ava's system
    emailPatient: false,
    locationIdx: data?.locationIdx ?? 0,
    oop: false,
    oopData: undefined,
    patientInfo: {
      firstName: "",
      lastName: "",
      phn: "",
      phone: "",
      email: "",
      dateOfBirth: "",
      age: "",
    },
    patientProvince:
      profile?.locations[data?.locationIdx ?? 0]?.province ?? "AB",
    phoneConsultData: {
      startTime: undefined,
      endTime: undefined,
      physicianPhoneNumber: "",
      physicianCalendarEmail: "",
    },
    specialty: data?.specialty ?? "",
    serviceId: "",
    subsite: "",
    symptoms: {},
  };
};

const isDisabled = (processState: ProcessState) =>
  processState === ProcessState.running ||
  processState === ProcessState.success;

/**
 * Consult form controller
 * Stores and sets all state in consult form
 *
 * What does go in here
 * These rules put state setting in one place (this file), so it is easier to find where state is being set from
 * * State
 * * React hook form useForm
 * * Handlers that set state
 * * Side-effects that set state
 *
 * What does NOT go in here.
 * These rules put data close to where it is used, and reduce re-renders
 * * Side-effects that do not set state (ex. Trigger validation on a field when another field changes. That should go in the component for that field.)
 * * State deriving functions (ie. functions that return a value based on state. The function should live where it is used, and state should be acquired using the useWatch hook)
 */

const ConsultFormController = (defaultLocationIdx?: number) => {
  const authContext = useContext(AuthContext);

  const {
    unregister,
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    reset,
    watch,
  } = useForm({
    mode: "onTouched",
    defaultValues: useMemo(
      () =>
        getConsultFormFields(
          { locationIdx: defaultLocationIdx }, // Set location index of the default clinic by default
          authContext.profile,
        ),

      [authContext.profile],
    ),
  });

  const { isDirty } = useFormState({ control: control });
  const { draftId, setDraftId } = useDraftId();
  const history = useHistory();

  const [econsultForwarder, setEconsultForwarder] = useState<
    Activity.EconsultForwarder | undefined
  >(undefined);

  const [selectedService, setSelectedService] = useState<ServiceItem | null>(
    null,
  );
  const [selectedApproverUid, setSelectedApproverUid] = useState<
    string | undefined
  >(undefined);
  const locationIdx: number = watch("locationIdx");
  const patientProvince: Province = watch("patientProvince");
  const patientEmail: string = watch("patientInfo.email");

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });
  const [loadingMessage, setLoadingMessage] = useState("");

  const [emrFormFields, setEmrFormFields] = useState<{
    [fieldName: string]: string;
  }>({});

  const {
    selectedMedia,
    selectHandler,
    selectMultipleHandler,
    unselectHandler,
    unselectAllHandler,
    isDirty: isGalleryDirty,
    setDirty: setGalleryDirty,
  } = SelectGalleryController({});

  const [showGallery, setShowGallery] = useState(false);

  const {
    files,
    handleUploadFilesWithPreview,
    resetFiles,
    createFileList,
    createFileThumbs,
    createDropzone,
    createUploadFromClipboardButton,
    isDirty: isFilesDirty,
    setDirty: setFilesDirty,
  } = useFileDrop({
    disabled: isDisabled(processState),
  });

  const [hideForm, setHideForm] = useState(true);

  /**
   * React hook form submit handler
   * @param data Data passed from react-hook-form
   * @returns
   */
  const onSubmit: SubmitHandler<Activity.ConsultFormFields> = (
    data: Activity.ConsultFormFields,
  ) => {
    setProcessState(ProcessState.running);

    // Log analytics
    logAnalyticsEvent(
      data?.searchType === "search"
        ? analyticsLogs.services.consultSentFromSearchBar
        : analyticsLogs.services.consultSentFromDirectory,
    );

    if (authContext.user === undefined) {
      errorHandler({
        userMessage: "You are not logged in. Please refresh the page.",
      });
      return;
    }
    const user = authContext.user;

    const requestAttachmentHandler = new RequestAttachmentHandler();

    setLoadingMessage("Uploading attachments...");
    return requestAttachmentHandler
      .uploadAttachments(user.uid, Object.values(files))
      .then(() => {
        setLoadingMessage(`Sending ${getActivityName(data.activityType)} ...`);
        return submitConsult(
          user as firebase.User,
          data,
          selectedMedia,
          requestAttachmentHandler.getAttachmentPaths(),
          econsultForwarder,
        );
      })
      .then(() => {
        return requestAttachmentHandler.clearAttachments();
      })
      .then(() => {
        if (data.activityType === "econsult")
          logAnalyticsEvent(analyticsLogs.econsult.send, {
            numGalleryFiles: Object.keys(selectedMedia).length,
            numAttachedFiles: Object.keys(files).length,
          });
        else if (data.activityType === "phoneConsult")
          logAnalyticsEvent(analyticsLogs.phoneConsult.send, {
            numGalleryFiles: Object.keys(selectedMedia).length,
            numAttachedFiles: Object.keys(files).length,
          });

        // Discard draft after successfully sending consult
        if (draftId !== undefined) {
          // Which collection are we looking at? drafts or approvals, delete accordingly
          const params = parse(location.search);
          const draftCollection = params.collection ?? "user_drafts";

          discardDraft(draftCollection, draftId)
            .then(() => {})
            .catch((error: Error) => {
              console.error(error);
            });
        }

        setProcessState(ProcessState.success);

        setTimeout(() => {
          clearForm(data);
          setProcessState(ProcessState.idle);
        }, 1500);
      })
      .catch((error: Error) => {
        let analyticsLog = undefined;
        if (data.activityType === "econsult")
          analyticsLog = analyticsLogs.econsult.sendFail;
        else if (data.activityType === "phoneConsult")
          analyticsLog = analyticsLogs.phoneConsult.sendFail;

        errorHandler({
          error: error,
          userMessage: "Error sending eConsult",
          analyticsLog: analyticsLog,
        });

        // Clean up attachments on failure as well
        return requestAttachmentHandler.clearAttachments();
      });
  };

  const onError: SubmitErrorHandler<Activity.ConsultFormFields> = () => {
    errorHandler({
      userMessage: "Check form for errors.",
    });
  };

  const clearForm = (data?: Activity.ConsultFormFields) => {
    cleanForm(getConsultFormFields(data, authContext.profile));
    resetFiles();
    unselectAllHandler(false);
    handleDeselectService();
    setDraftId(undefined);
    setEconsultForwarder(undefined); // Also clear econsultForwarder
  };

  /**
   * Sets the form to a clean state, while maintaining the data in the form
   * @param formData
   */
  const cleanForm = (formData?: Activity.ConsultFormFields) => {
    reset(formData);
    setGalleryDirty(false);
    setFilesDirty(false);
  };

  // This will cause reset to be called twice when discarding a draft (since the search params are cleared when discarding), but that is fine.
  // It needs to be called in discard draft if there are other query parameters present (in which case location.search !== "")
  useEffect(() => {
    if (history !== undefined) {
      const unsubscribe = history.listen(() => {
        // Reset form when "New consult" button is pressed in sidebar
        if (location.search === "") clearForm();
      });
      return unsubscribe;
    }
  }, [history]);

  //#region Handlers

  const handleSelectLocation = useCallback(
    (newLocationIdx: number) => {
      // Set the patient province to the physician's province by default
      // Only set when location selected by user (in case user changed preference previously, and we are loading from a draft)
      if (
        authContext?.profile?.locations[newLocationIdx]?.province !== undefined
      ) {
        setValue(
          "patientProvince",
          authContext.profile.locations[newLocationIdx].province,
        );
      }
    },
    [setValue, authContext?.profile?.locations],
  );

  const handleSelectService = useCallback(
    (service: ServiceItem) => {
      setValue("serviceId", service.id, { shouldDirty: true });
      setValue("specialty", service.service.specialty, { shouldDirty: true });
      setValue("subsite", service.service.subsite, { shouldDirty: true });

      // When loading a draft service is not validated unless we wait for the next render cycle
      setTimeout(() => {
        trigger("serviceId");
      }, 1);
      setSelectedService(service);
      setHideForm(false);
    },
    [setValue, setHideForm, setSelectedService],
  );

  const handleDeselectService = useCallback(() => {
    setValue("serviceId", "");

    setSelectedService(null);
    setHideForm(true);
  }, [setValue, setHideForm, setSelectedService]);

  const handleSelectApproverUid = useCallback(
    (approverUid: string) => {
      setSelectedApproverUid(approverUid);
    },
    [setSelectedApproverUid],
  );

  const handleDeselectApproverUid = useCallback(() => {
    setSelectedApproverUid(undefined);
  }, [setSelectedApproverUid]);

  const handleForwardEconsult = () => {
    if (!authContext.profile || !authContext.user?.email) return;
    if (!selectedApproverUid) return;

    const formData = getValues();
    setProcessState(ProcessState.running);
    setLoadingMessage("Forwarding eConsult...");

    const econsultForwarder: Activity.EconsultForwarder = {
      uid: authContext.uid,
      firstName: authContext.profile.firstName,
      lastName: authContext.profile.lastName,
      email: authContext.user.email,
    };

    saveOrUpdateDraft(
      "drafts_pending_approval",
      authContext.uid,
      draftId,
      formData,
      "",
      selectedMedia,
      files,
      econsultForwarder,
      selectedApproverUid,
    )
      .then(({ newDraftId, newDraftSubject }) => {
        logAnalyticsEvent(
          draftId === undefined
            ? analyticsLogs.consultDraft.forward
            : analyticsLogs.consultDraft.update,
        );

        // Load form data and set isDirty flags for form, gallery, and attachments to false
        cleanForm(formData);

        // Discard draft after successfully sending consult
        if (draftId !== undefined) {
          discardDraft("user_drafts", draftId)
            .then(() => {})
            .catch((error: Error) => {
              console.error(error);
            });
        }

        setTimeout(() => {
          // Set new draft ID after resetting page to not dirty, so leavePagePrompt doesn't activate when query parameters update
          setDraftId(newDraftId);
        }, 1);

        setProcessState(ProcessState.success);
        // setSuccessMessage("Draft forwarded.")
        setTimeout(() => {
          // Clear form data & return to search page (work is complete now)
          clearForm(formData);

          // Either new ID if saving new, or same as before if updating
          setProcessState(ProcessState.idle);
        }, 2000);
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error forwarding econsult",
          analyticsLog:
            draftId === undefined
              ? analyticsLogs.consultDraft.forwardFail
              : analyticsLogs.consultDraft.updateFail,
        });
      });
  };

  const sendEconsultButtonVisible = hasPermissions(
    resourceKeys.econsultAndSecureMessaging,
    authContext.profile,
  );

  // Only show Forward button if user has forward_econsult role
  const forwardEconsultButtonVisible = hasPermissions(
    resourceKeys.forwardEconsult,
    authContext.profile,
  );

  // Only enable forward button if approver is selected
  const forwardButtonEnabled = selectedApproverUid !== undefined;

  const handleClearSpecialtyAndSubsite = useCallback(() => {
    setValue("specialty", "");
    setValue("subsite", "");
  }, [setValue]);

  const handleSelectPatient = useCallback(
    (newPatientInfo: PatientInfoWithFormattedDoB) => {
      if (newPatientInfo.phn)
        setValue("patientInfo.phn", newPatientInfo.phn, {
          shouldValidate: true,
          shouldDirty: true,
        });
      if (newPatientInfo.firstName)
        setValue("patientInfo.firstName", newPatientInfo.firstName, {
          shouldValidate: true,
          shouldDirty: true,
        });
      if (newPatientInfo.lastName)
        setValue("patientInfo.lastName", newPatientInfo.lastName, {
          shouldValidate: true,
          shouldDirty: true,
        });
      if (newPatientInfo.email)
        setValue("patientInfo.email", newPatientInfo.email, {
          shouldValidate: true,
          shouldDirty: true,
        });
      if (newPatientInfo.phone)
        setValue("patientInfo.phone", newPatientInfo.phone, {
          shouldValidate: true,
          shouldDirty: true,
        });

      // Prioritize formatted DoB first
      if (newPatientInfo.dateOfBirthYYYYMMDD !== undefined) {
        const regex = /^\d{4}\/\d{2}\/\d{2}$/;
        // Don't add DoB if it is being passed as formatted, but is formatted improperly
        if (regex.test(newPatientInfo.dateOfBirthYYYYMMDD)) {
          setValue(
            "patientInfo.dateOfBirth",
            newPatientInfo.dateOfBirthYYYYMMDD,
            { shouldValidate: true, shouldDirty: true },
          );
        }
      } else if (newPatientInfo.dateOfBirth !== undefined) {
        try {
          setValue(
            "patientInfo.dateOfBirth",
            formatDateAsYYYYMMDD(newPatientInfo.dateOfBirth.toDate()),
            { shouldValidate: true, shouldDirty: true },
          );
        } catch (e) {
          // Fail silently
          console.error(`Error parsing patient date of birth: ${e}`);
        }
      }
    },
    [setValue],
  );

  const handleAddEmrMedicalHistory = useCallback(
    (medicalHistory: string) => {
      setEmrFormFields({ "Medical History": medicalHistory });
    },
    [setEmrFormFields],
  );

  /**
   * Handles updating fields in the service form
   * @param header symptom field name
   * @param value value to assign
   */
  const handleUpdateSymptomField = useCallback(
    (header: string, value: string) => {
      const encodedHeader = formSafeEncode(header);
      setValue(`symptoms.${encodedHeader}`, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  const handleEmrLink = useCallback(
    (consultId: string, uuid: string) => {
      setValue("avaData.avaConsultId", consultId);
      setValue("avaData.avaUuid", uuid);
    },
    [setValue],
  );

  const handleResetSubsite = useCallback(() => {
    setValue("subsite", "");
  }, [setValue]);

  const handleOpenGallery = () => {
    setShowGallery(true);
  };

  const handleCloseGallery = () => {
    setShowGallery(false);
  };

  const handleChangeSearchType = (
    searchType: Activity.ConsultFormFields["searchType"],
  ) => {
    setValue("searchType", searchType);
  };

  //#endregion

  //#region State-setting Use Effects
  useEffect(() => {
    // Check if patient is OOP or not
    // OOP is solely controlled by this, so we can use a useEffect here and not interfere with draft loading
    if (
      (selectedService?.service?.clinic?.location?.province === "AB" ||
        authContext?.profile?.locations[locationIdx]?.province === "AB") &&
      patientProvince !== "AB" &&
      patientProvince !== "QC"
    ) {
      setValue("oop", true);
    } else {
      setValue("oop", false);
    }
  }, [patientProvince, selectedService, locationIdx]);

  useEffect(() => {
    if (patientEmail === "") {
      setValue("emailPatient", false);
    }
  }, [patientEmail]);

  //#endregion

  return {
    handleSubmit: handleSubmit(onSubmit, onError),
    draftId,
    setDraftId,
    // State
    isDisabled: isDisabled(processState),
    processState,
    processErrorMessage,
    loadingMessage,
    selectedService,
    hideForm,
    // Gallery
    showGallery,
    selectedMedia,
    selectHandler,
    selectMultipleHandler,
    unselectHandler,
    unselectAllHandler,
    files,
    handleUploadFilesWithPreview,
    // React hook form
    control,
    setValue,
    watch,
    getValues,
    trigger,
    unregister,
    isDirty: isDirty || isGalleryDirty || isFilesDirty,
    cleanForm,
    clearForm,
    // Dropzone
    createFileList,
    createFileThumbs,
    createDropzone,
    createUploadFromClipboardButton,
    // EMR Form fields
    emrFormFields,
    // Handlers
    handleSelectLocation,
    handleSelectService,
    handleDeselectService,
    handleClearSpecialtyAndSubsite,
    handleSelectPatient,
    handleAddEmrMedicalHistory,
    handleEmrLink,
    handleOpenGallery,
    handleCloseGallery,
    handleResetSubsite,
    handleChangeSearchType,
    handleUpdateSymptomField,
    selectedApproverUid,
    handleSelectApproverUid,
    handleDeselectApproverUid,
    sendEconsultButtonVisible,
    forwardEconsultButtonVisible,
    forwardButtonEnabled,
    handleForwardEconsult,
    econsultForwarder,
    setEconsultForwarder,
  };
};

export default ConsultFormController;
