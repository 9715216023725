import React, { useEffect, useState } from "react";
import EconsultCard from "./EconsultCard";
import { fbFirestore } from "../../../../firebase";

import { Activity, ConsultFormInputField, ConsultFormSpecialty } from "../../../../../shared/types";

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { getDocumentData } from "@alethea-medical/utilities";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import palette from "../../../../palette";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
        checkTypographyOrRadio: {
            paddingLeft: theme.spacing(2)
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        section: {
            marginTop: theme.spacing(1)
        },
        header: {
            color: palette.darkGreen,
        },
        content: {
            paddingLeft: theme.spacing(3)
        }
	}),
);

interface EconsultInfoProps {
    econsult: Activity.Econsult
}

const EconsultInfo = ({ econsult }: EconsultInfoProps) => {
    const classes = useStyles();
    const bullet = <span className={classes.bullet}>•</span>;
    
    const [sortedSymptoms, setSortedSymptoms] = useState<{header: string, value: any}[]>([]);

    const getSymptomIndex = (header: string, subsite: ConsultFormInputField[]) => {
        return subsite.map((field: ConsultFormInputField) => field.header).indexOf(header);
    }

    useEffect(() => {
        if(econsult.specialty !== "" && econsult.specialty !== undefined) {
            fbFirestore.collection(dbNames.specialties).doc(econsult.specialty).get().then(getDocumentData)
            .then((specialty: ConsultFormSpecialty) => {
                const subsite: ConsultFormInputField[] = specialty[econsult.subsite];
                const headers = Object.keys(econsult.symptoms).sort((a: string, b: string) => {
                    const indexA = getSymptomIndex(a, subsite);
                    const indexB = getSymptomIndex(b, subsite);
                    return indexA < indexB ? -1 : 1;
                })
                
                setSortedSymptoms(headers.map((header: string) => {
                    return {
                        header: header,
                        value: econsult.symptoms[header]
                    }
                }));
            }).catch((error: Error) => {
                //Fall back if we can't load the specialty
                setSortedSymptoms(Object.keys(econsult.symptoms).map((header: string) => {
                    return {
                        header: header,
                        value: econsult.symptoms[header]
                    }
                }));
            })            
        }

    }, [econsult])

    return (
        <>
            {sortedSymptoms.length > 0 && 
                <Grid item xs={12}>
                    <EconsultCard>
                        <Grid container spacing={1}>
                            {sortedSymptoms.map((keyValue: any) => {
                                const key = keyValue.header;
                                const value = keyValue.value;
                                if(typeof value ===  "string") {//Textfield
                                    return (
                                        <Grid item xs={12} key={`textfield_${key}`}>
                                            <Typography className={classes.header}>
                                                {`${key}`}
                                            </Typography>
                                            <Typography className={classes.content}>
                                                {value !== "" ? value : "No information provided"}
                                            </Typography>
                                        </Grid>
                                    );
                                }
                                else {
                                    return (
                                        <Grid item xs={12} key={`header_${key}`}>
                                            <Typography className={classes.header}>
                                                {`${key}`}
                                            </Typography>
                                            {Object.keys(value).map((entry: string) => {
                                                if(typeof value[entry] === "boolean" && value[entry]) {//Checkboxes
                                                    return (
                                                        <Typography key={`checkbox_${entry}`} className={classes.content}>
                                                            {bullet}{entry}
                                                        </Typography>
                                                    );
                                                }
                                                else if(typeof value[entry] === "string" && value[entry] !== '') {//Radio
                                                    return (
                                                        <Typography key={`radio_${entry}`} className={classes.content}>
                                                            {bullet}{entry}: {value[entry]}
                                                        </Typography>
                                                    );
                                                }
                                            })}
                                        </Grid>
                                    );
                                }
                            })}
                        </Grid>
                    </EconsultCard>           
                </Grid>
            }
        </>
    );

}
export default EconsultInfo;