import { ProcessStatus } from "@alethea-medical/alethea-components";
import { Slide } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import { Control, useWatch } from "react-hook-form";
import OneTimeTooltip from "src/components/OneTimeTooltip";
import LeavePagePrompt from "../../../components/LeavePagePrompt";
import ConsultAttachments from "./Attachments/ConsultAttachments";
import AvaEconsultEndpoint from "./Ava/AvaEconsultEndpoint";
import ConsultFormCard from "./ConsultFormCard";
import ConsultFormController from "./ConsultFormController";
import ConsultLocationSelector from "./ConsultLocationSelector";
import ConsultDraftButtons from "./Draft/ConsultDraftButtons";
import ConsultDraftController from "./Draft/ConsultDraftController";
import ConsultDraftSubject from "./Draft/ConsultDraftSubject";
import ConsultGallery from "./Gallery/ConsultGallery";
import ConsultGalleryPreviewer from "./Gallery/ConsultGalleryPreviewer";
import ConsultPatientInfoForm from "./PatientInfoForm/ConsultPatientInfoForm";
import ServiceSearchContainer from "./SearchAndResults/ServiceSearchContainer";
import { ServiceSearchControl } from "./SearchAndResults/ServiceSearchController";
import ServiceFormContainer from "./ServiceForm/ServiceFormContainer";
import useConsultFormStyles from "./useConsultFormStyles";
import { useState, useContext } from "react";
import ApproverSearchBar from "./SearchAndResults/Approver/ApproverSearchBar";
import { getDefaultClinicIndex } from "../../../utils/locationUtils";
import { AuthContext } from "../../../AuthProvider";
import { usePermissionsContext } from "./SearchAndResults/PermissionsContext";

const ConsultContainer = () => {
  const classes = useConsultFormStyles();
  const authContext = useContext(AuthContext);
  const imageWarningLimit = 8;

  const {
    handleSubmit,
    draftId,
    setDraftId,
    // State
    isDisabled,
    processState,
    processErrorMessage,
    loadingMessage,
    hideForm,
    selectedService,
    // Gallery
    showGallery,
    selectedMedia,
    selectHandler,
    selectMultipleHandler,
    unselectHandler,
    unselectAllHandler,
    files,
    handleUploadFilesWithPreview,
    // React hook form
    control,
    getValues,
    trigger,
    unregister,
    isDirty,
    cleanForm,
    clearForm,
    setValue,
    watch,
    // Dropzone
    createFileList,
    createFileThumbs,
    createDropzone,
    createUploadFromClipboardButton,
    // EMR Form Fields
    emrFormFields,
    // Handlers
    handleSelectLocation,
    handleSelectService,
    handleDeselectService,
    handleClearSpecialtyAndSubsite,
    handleSelectPatient,
    handleAddEmrMedicalHistory,
    handleEmrLink,
    handleOpenGallery,
    handleCloseGallery,
    handleResetSubsite,
    handleChangeSearchType,
    handleUpdateSymptomField,
    selectedApproverUid,
    handleSelectApproverUid,
    handleDeselectApproverUid,
    sendEconsultButtonVisible,
    forwardEconsultButtonVisible,
    forwardButtonEnabled,
    handleForwardEconsult,
    econsultForwarder,
    setEconsultForwarder,
  } = ConsultFormController(
    getDefaultClinicIndex(authContext.profile?.locations ?? []), // Get default clinic index, pass it to the controller so default clinic can be set correctly
  );

  const {
    saveDraftHandler,
    discardDraftHandler,
    draftSubject,
    setDraftSubject,
    draftState,
    draftError,
    setDraftState,
    draftLoadingMessage,
    draftSuccessMessage,
    isLoadingDraft,
  } = ConsultDraftController({
    draftId,
    setDraftId,
    cleanForm,
    clearForm,
    getValues,
    selectService: handleSelectService,
    selectedMedia,
    files,
    selectMultipleHandler,
    handleUploadFilesWithPreview,
    econsultForwarder,
    setEconsultForwarder,
  });

  const [galleryAnchor, setGalleryAnchor] = useState<HTMLElement | null>(null);
  const [showForwardButton, setShowForwardButton] = useState<boolean>(false);
  const [disableSendButton, setDisableSendButton] = useState<boolean>(false);

  forwardEconsultButtonVisible.then((visible) => {
    setShowForwardButton(visible);
  });

  sendEconsultButtonVisible.then((visible) => {
    setDisableSendButton(!visible);
  });

  // Only let
  const handleSubmitWrapper = (event: any) => {
    if (!selectedService) {
      // Don't let form submission unless a service is selected
      event.preventDefault(); // Prevent page from reloading
      return false;
    }

    handleSubmit(event);
  };

  const { permissions, loading } = usePermissionsContext();

  return (
    // Render centered container with max width
    <Container maxWidth={"md"} className={classes.container}>
      <div
        className={clsx({
          [classes.displayNone]: !showGallery,
        })}
      >
        <Slide direction="left" in={showGallery} mountOnEnter>
          <div>
            <ConsultGallery
              selectedMedia={selectedMedia}
              selectHandler={selectHandler}
              unselectHandler={unselectHandler}
              unselectAllHandler={unselectAllHandler}
              onCloseGallery={handleCloseGallery}
              imageWarningLimit={imageWarningLimit}
            />
          </div>
        </Slide>
      </div>

      <div
        className={clsx(classes.form, {
          [classes.displayNone]: showGallery,
        })}
      >
        <form
          onSubmit={handleSubmitWrapper}
          autoComplete="off"
          aria-autocomplete="none"
        >
          {/* Draft state, buttons, subject display, and subject input */}
          {/* Draft loading state */}
          <ProcessStatus
            state={draftState}
            errorMessage={draftError}
            setState={setDraftState}
            useSnackbar
            hideProgressBar
            loadingMessage={draftLoadingMessage}
            successMessage={draftSuccessMessage}
          />
          <ConsultDraftButtons
            control={control}
            draftSubject={draftSubject}
            setDraftSubject={setDraftSubject}
            saveDraftHandler={saveDraftHandler}
            discardDraftHandler={discardDraftHandler}
            disabled={isDisabled || isLoadingDraft}
            draftId={draftId}
            isDirty={isDirty}
          />
          {/* Only show subject if a draft is loaded (draftId is defined) */}
          {draftId && <ConsultDraftSubject draftSubject={draftSubject} />}

          {!isLoadingDraft && (
            <>
              {econsultForwarder && (
                <ConsultFormCard
                  className={classes.spacing}
                  style={{ backgroundColor: "#e3f3ef" }}
                >
                  <h3 style={{ color: "#05707d", marginBottom: 0 }}>
                    {" "}
                    This eConsult was forwarded, currently pending approval by
                    you.
                  </h3>
                  <p style={{ color: "#05707d", marginTop: 0 }}>
                    Sent by {econsultForwarder.firstName}{" "}
                    {econsultForwarder.lastName} ({econsultForwarder.email})
                  </p>
                </ConsultFormCard>
              )}

              {/* Choose referring clinic dropdown */}
              <ConsultFormCard className={classes.spacing}>
                <ConsultLocationSelector
                  control={control}
                  disabled={isDisabled}
                  onSelectLocation={handleSelectLocation}
                  setValue={setValue}
                />
              </ConsultFormCard>

              {/* Search bar and search results */}
              <ConsultFormCard className={classes.spacing}>
                <ServiceSearchContainer
                  selectedService={selectedService}
                  selectService={handleSelectService}
                  clearSpecialtyAndSubsite={handleClearSpecialtyAndSubsite}
                  changeSearchType={handleChangeSearchType}
                  deselectService={handleDeselectService}
                  control={control as unknown as Control<ServiceSearchControl>}
                  resetSubsite={handleResetSubsite}
                  permissions={permissions}
                />
              </ConsultFormCard>

              <AvaEconsultEndpoint
                control={control}
                onAddEmrMedicalHistory={handleAddEmrMedicalHistory}
                onEmrPatientInfo={handleSelectPatient}
                onEmrLink={handleEmrLink}
              />

              <div
                className={clsx({
                  [classes.displayNone]: hideForm,
                })}
              >
                <OneTimeTooltip
                  visible={!hideForm && !showGallery}
                  onScreenAnchor={galleryAnchor}
                  tooltipName="gallery_moved_dec_19_2023"
                  title='The gallery has moved. Click "Open Gallery" to attach images to your consult.'
                  placement="top"
                >
                  {/* Patient info form, with OOP input */}
                  <ConsultFormCard
                    title="Patient Information"
                    className={classes.spacing}
                  >
                    <ConsultPatientInfoForm
                      control={control}
                      trigger={trigger}
                      setValue={setValue}
                      watch={watch}
                      disabled={isDisabled}
                      onSelectPatient={handleSelectPatient}
                      serviceProvince={
                        selectedService?.service.clinic.location.province
                      }
                    />
                  </ConsultFormCard>
                </OneTimeTooltip>

                <div className={classes.spacing}>
                  <ServiceFormContainer
                    control={control}
                    emrFormFields={emrFormFields}
                    unregister={unregister}
                    updateSymptomField={handleUpdateSymptomField}
                    disabled={isDisabled}
                  />
                </div>

                {/* Gallery */}
                <div ref={setGalleryAnchor}>
                  <ConsultFormCard
                    title="Gallery Attachments"
                    className={classes.spacing}
                  >
                    <ConsultGalleryPreviewer
                      selectedMedia={selectedMedia}
                      selectHandler={selectHandler}
                      unselectHandler={unselectHandler}
                      unselectAllHandler={unselectAllHandler}
                      onOpenGallery={handleOpenGallery}
                      imageWarningLimit={imageWarningLimit}
                    />
                  </ConsultFormCard>
                </div>

                {/* Attachments */}
                <ConsultFormCard
                  title="Other Attachments"
                  className={classes.spacing}
                >
                  <ConsultAttachments
                    createUploadFromClipboardButton={
                      createUploadFromClipboardButton
                    }
                    createDropzone={createDropzone}
                    createFileList={createFileList}
                    createFileThumbs={createFileThumbs}
                  />
                </ConsultFormCard>

                {/* If forward_econsult, should have search bar to select approver */}
                {showForwardButton && (
                  <ConsultFormCard
                    title="Select Approver"
                    className={classes.spacing}
                  >
                    <ApproverSearchBar
                      selectedApproverUid={selectedApproverUid}
                      selectApproverUid={handleSelectApproverUid}
                      deselectApproverUid={handleDeselectApproverUid}
                    />
                  </ConsultFormCard>
                )}

                <div className={classes.spacing}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isDisabled || disableSendButton}
                  >
                    Submit
                  </Button>

                  {showForwardButton && (
                    <Button
                      className={classes.leftMargin}
                      variant="contained"
                      color="primary"
                      disabled={!forwardButtonEnabled}
                      onClick={handleForwardEconsult}
                    >
                      Forward
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </form>
        {/* Submit */}
        <div className={classes.spacing}>
          <ProcessStatus
            state={processState}
            errorMessage={processErrorMessage}
            successMessage="eConsult Sent Successfully"
            loadingMessage={loadingMessage}
          />
        </div>
      </div>
      <LeavePagePrompt
        isDirty={isDirty}
        message={
          "You have unsaved changes, are you sure you want to leave? You can save your changes by pressing Save Draft."
        }
      />
    </Container>
  );
};

export default ConsultContainer;
