import { Activity } from "@alethea-medical/aletheamd-types";
import EconsultCard from "./EconsultCard";
import Typography from "@material-ui/core/Typography";

interface EconsultProfileProps {
  user: Activity.ActivityUser;
}

const EconsultProfile = ({ user }: EconsultProfileProps) => {
  return (
    <>
      {/* If current user is physician, then show physician text, otherwise show specialist text */}
      <EconsultCard
        title={Activity.ActivityRole.getDisplayName(user.activityRole)}
      >
        <Typography>
          {user.firstName} {user.lastName}
        </Typography>
        <Typography>{user.credentials}</Typography>
        <Typography>
          {user.location.clinicName} - {user.location.city},{" "}
          {user.location.province}
        </Typography>
        <Typography>Fax: {user.location.fax}</Typography>
      </EconsultCard>
    </>
  );
};

export default EconsultProfile;
