import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthProvider";
import hasPermissions from "../../models/hasPermissions";

interface usePermissionsProps {
    resourceKey: string,
    onPermissionFetched?: (granted: boolean) => void
}

const usePermissions = ({ resourceKey, onPermissionFetched=() => {} }: usePermissionsProps) => {
    
    const authContext = useContext(AuthContext)

    const [ granted, setGranted ] = useState(false)
    useEffect(() => {
        hasPermissions(resourceKey, authContext.profile)
        .then((granted) => {
            setGranted(granted)
            onPermissionFetched(granted)
        })
    }, [authContext.profile])
    
    return {
        granted
    }
}

export default usePermissions;