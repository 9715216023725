import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ActivityListItem from "./ListItem/ActivityListItem";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import ViewSecureMessage from "../ViewSecureMessage";
import { thinScrollbar } from "@alethea-medical/alethea-components";
import SnackbarMessage from "../../../../components/SnackbarMessage";
import Grid from '@material-ui/core/Grid';
import SecureMessagingToolbar from "../Toolbar/SecureMessagingToolbar";
import Checkbox from '@material-ui/core/Checkbox';

import { SpecialistProvider } from "../Utilities/SpecialistProvider";
import { ProcessStatus, HeightLayout, HeightLayoutChild } from "@alethea-medical/alethea-components";
import InboxController from "./Controllers/InboxController";
import ConsultSearchBar from './ConsultSearchBar';
import { Activity } from '../../../../../shared/types';
import ActivityProvider from './Contexts/ActivityProvider';
import { useContext } from 'react';
import { AuthContext } from '../../../../AuthProvider';
import InboxModel from './Models/InboxModel';
import { dbNames } from '@alethea-medical/aletheamd-db-keys';
import ActivitiesListController from './Controllers/ActivitiesListController';
import ActivitiesListModel from './Models/ActivitiesListModel';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		...thinScrollbar,
		box: {
			borderColor: theme.palette.grey[300],
		},
		threadSidebar:  {
			padding: 0,
		},
		threadListItem: {
			padding: 0,
		},
		threadList: {
			padding: 0
		},
		hidden: {
			display: "none"
		},
		toolbar: {
            paddingLeft: theme.spacing(1),
        },
		inboxControlsBar: {
			paddingTop: theme.spacing(0),
			paddingBottom: theme.spacing(0)
		},
		tabSwitcher: {
			paddingLeft: theme.spacing(1)
		}
	}),
);


interface InboxViewProps {
    height: number,
    folder: Activity.UserActivityFolder,
    setShowTabs: (show: boolean) => void
}

const InboxView = ({ height, folder, setShowTabs }: InboxViewProps) => {
    const classes = useStyles();

	const authContext = useContext(AuthContext)

	const {
		sortedActivities, activities,
		updateActivitiesInState, removeActivitiesFromState,
        loadMoreHandler,
        disableLoadMoreLoading,
		disableLoadMoreEndOfResults,
        
        isActivityOpen, openActivityHandler,

        // Select
        selectedActivities, allSelected,
        selectAllHandler, activitySelectHandler,
		unselectAllHandler,

        // Search
        enableSearch,
        runSearch, clearSearch,

        processState, setProcessState, processErrorMessage, errorHandler

    } = InboxController<Activity.UserActivity>({ 
		inboxModel: new InboxModel(authContext.uid, dbNames.activities, dbNames.userActivities, dbNames.userActivities_activities, "lastMessageReceivedAt"), 
		folder, metadataFieldToSortBy: "lastMessageReceivedAt", setShowTabs });

	const activitiesListController = ActivitiesListController({ activitiesListModel: new ActivitiesListModel(authContext.uid), activities, updateActivitiesInState, removeActivitiesFromState, unselectAllHandler, errorHandler })

    return (
        <>

			<SpecialistProvider>
				<div className={isActivityOpen ? classes.hidden : ""}> 
					<List className={classes.threadSidebar}>
						<HeightLayout height={height}>
							<HeightLayoutChild flexDriver>
								<ListItem 
									divider
								>
									<ConsultSearchBar enableSearch={enableSearch}
										runSearch={runSearch} clearSearch={clearSearch}
										placeholderText={'PHN, Patient Name, Specialty, Subsite'}
										maxSearchTerms={10}
									/>
								</ListItem>
								<ProcessStatus state={processState} setState={setProcessState} errorMessage={processErrorMessage} useSnackbar={true}/>
								<ListItem className={classes.toolbar} divider>
									<Grid container spacing={1} justifyContent="flex-start" alignItems="center">
										<Grid item>
											<Checkbox checked={allSelected} onChange={(e) => { selectAllHandler(e.target.checked) }}/>
										</Grid>
										<Grid item>
											<SecureMessagingToolbar 
												folder={folder}
												atLeastOneSelected={selectedActivities.length > 0}
												markAsUnread={() => { activitiesListController.markAsUnreadHandler(selectedActivities)}} 
												moveFolder={(folder) => { activitiesListController.moveFolderHandler(selectedActivities, folder)}} 
											/>
										</Grid>
									</Grid>
								</ListItem>
							</HeightLayoutChild>
							<HeightLayoutChild flexDriven allowOverflowY className={classes.thinScrollbar}>
								{sortedActivities.map((a) =>
									<ActivityListItem 
										key={`activity_list_item_${a.id}`} 
										activity={a} 
										openActivity={openActivityHandler}
										selected={selectedActivities.includes(a.id)}
										setSelected={activitySelectHandler}
									/>
								)}	
								<ListItem
									button
									onClick={loadMoreHandler}
									disabled={disableLoadMoreLoading || disableLoadMoreEndOfResults}
									alignItems="center"
									divider
								>
									<ListItemIcon>
										<ArrowDownwardIcon color="primary"/>
									</ListItemIcon>
									<ListItemText
										primary={disableLoadMoreLoading ? "Loading" : (disableLoadMoreEndOfResults ? "End of Consults" : (enableSearch ? "Load More Search Results" : "Load More"))}
									/>
								</ListItem>
							</HeightLayoutChild>
						</HeightLayout>
					</List>		
				</div>
				<ActivityProvider updateActivitiesInListState={updateActivitiesInState} activitiesListController={activitiesListController} viewType="md" metadataRefId={authContext.uid} metadataCollection={dbNames.userActivities} metadataSubCollection={dbNames.userActivities_activities}>
					<ViewSecureMessage />
				</ActivityProvider>
				<SnackbarMessage 
					message={`Moved to ${activitiesListController.moveSnackbarFolderAction}`} 
					show={activitiesListController.showUndoSnackbar} 
					setShow={activitiesListController.setShowUndoSnackbar} 
					onClick={activitiesListController.undoMoveFolderHandler}
					useButton buttonText="UNDO" />
				<SnackbarMessage message={"Marked as unread"} show={activitiesListController.showMarkedAsUnreadSnackbar} setShow={activitiesListController.setShowMarkAsUnreadSnackbar}/>			
			</SpecialistProvider>

        </>
    );
}

export default InboxView;