import { AppRoute as AletheaAppRoute } from "@alethea-medical/alethea-components";
import { defaultRoles, resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import { Utilities } from "@alethea-medical/aletheamd-types";
import { multiPermissionsSatisfies } from "src/components/usePermissions";

type StringDict<T> = Utilities.StringDict<T>;

export interface AppRoute extends AletheaAppRoute {
  availableOnMobile: boolean;
  isValidHomepage: boolean;
  /**
        Main array is OR, subarray is AND 
        ex. [["resource1", "resource2"], ["resource3"]] 
            means (resource1 && resource2) || (resource3)
    */
  requiredResourcePermissions: string[][];
}

interface AppRoutes {
  [path: string]: AppRoute;
}

const routes: AppRoutes = {
  profile: {
    path: "/dashboard/profile",
    name: "Profile",
    isValidHomepage: true,
    availableOnMobile: true,
    requiredResourcePermissions: [[]],
  },
  camera: {
    path: "/dashboard/camera",
    name: "Camera",
    isValidHomepage: false,
    availableOnMobile: false,
    requiredResourcePermissions: [[resourceKeys.cameraAndGallery]],
  },
  gallery: {
    path: "/dashboard/gallery",
    name: "Gallery",
    isValidHomepage: true,
    availableOnMobile: true,
    requiredResourcePermissions: [[resourceKeys.cameraAndGallery]],
  },
  imageAnnotation: {
    path: "/dashboard/image-annotation",
    name: "Image Annotation",
    isValidHomepage: false,
    availableOnMobile: false,
    requiredResourcePermissions: [[resourceKeys.imageAnnotation]],
  },
  billingReport: {
    path: "/dashboard/billing-report",
    name: "Billing Report",
    isValidHomepage: false,
    availableOnMobile: false,
    requiredResourcePermissions: [[resourceKeys.billingReport]],
  },
  forms: {
    path: "/dashboard/form-sender",
    name: "Forms",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [[resourceKeys.forms]],
  },
  oneWayMessaging: {
    path: "/dashboard/one-way-messaging",
    name: "One-Way Messaging",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [[resourceKeys.oneWayMessaging]],
  },
  consult: {
    path: "/dashboard/consult",
    name: "Consult",
    isValidHomepage: true,
    availableOnMobile: true,
    requiredResourcePermissions: [
      [resourceKeys.econsultAndSecureMessaging],
      [resourceKeys.forwardEconsult],
      [resourceKeys.approveEconsult],
    ],
  },
  secureMessaging: {
    path: "/dashboard/secure-messaging",
    name: "Secure Messaging",
    isValidHomepage: true,
    availableOnMobile: true,
    requiredResourcePermissions: [[]],
  },
  moaConsultInbox: {
    path: "/dashboard/clinic-inbox",
    name: "Clinic Inbox",
    isValidHomepage: true,
    availableOnMobile: true,
    requiredResourcePermissions: [[resourceKeys.moaConsultInbox]],
  },
  patientMessages: {
    path: "/dashboard/patient-messages",
    name: "Patient Messages",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [
      [resourceKeys.oneWayMessaging],
      [resourceKeys.forms],
      [resourceKeys.patientCC],
      [resourceKeys.moaMessaging],
    ],
  },
  resources: {
    path: "/dashboard/resources",
    name: "General Resources",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [[]],
  },
  validationRequired: {
    path: "/dashboard/validation-required",
    name: "Next Steps",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [[resourceKeys.pracIdValidationRequiredPage]],
  },
  specialistTemplates: {
    path: "/dashboard/edit-templates",
    name: "Edit Templates",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [[resourceKeys.specialistTemplates]],
  },
  editResources: {
    path: "/dashboard/edit-resources",
    name: "Edit Resources",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [[resourceKeys.specialistTemplates]],
  },
  specialistSchedule: {
    path: "/dashboard/edit-schedule",
    name: "Edit Schedule",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [[resourceKeys.specialistScheduleEditing]],
  },
  notificationCenter: {
    path: "/dashboard/secure-messaging?tab=notifications",
    name: "Alethea News",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [[]],
  },
};

/** List of all routes that can be used as a homepage setting */
export const validHomepageRoutes = Object.fromEntries(
  Object.entries(routes).filter(([_, route]) => route.isValidHomepage),
);

/** List of all keys for all valid homepage routes */
export const validHomepageRoutesResourceKeys = Array.from(
  new Set(
    Object.values(validHomepageRoutes)
      .map((route) => route.requiredResourcePermissions)
      .flat()
      .flat(),
  ),
);

/** Gets the default homepage setting for the user */
export const getDefaultHomepageRoute = (
  permissionDict: { [key: string]: boolean },
  userRoles?: string[],
) => {
  if (
    userRoles?.includes(defaultRoles.specialist) &&
    multiPermissionsSatisfies(
      permissionDict,
      routes.secureMessaging.requiredResourcePermissions,
    )
  ) {
    return routes.secureMessaging;
  }

  if (
    multiPermissionsSatisfies(
      permissionDict,
      routes.consult.requiredResourcePermissions,
    )
  ) {
    return routes.consult;
  }

  if (
    multiPermissionsSatisfies(
      permissionDict,
      routes.moaConsultInbox.requiredResourcePermissions,
    )
  ) {
    return routes.moaConsultInbox;
  }

  if (
    multiPermissionsSatisfies(
      permissionDict,
      routes.gallery.requiredResourcePermissions,
    )
  ) {
    return routes.gallery;
  }

  // Fallback to profile if no permissions are granted
  return routes.profile;
};

/** Gets the key of the default homepage setting for the user */
export const getDefaultHomepageKey = (
  permissionDict: { [key: string]: boolean },
  userRoles?: string[],
) => {
  const defaultHomepageRouteString = JSON.stringify(
    getDefaultHomepageRoute(permissionDict, userRoles),
  );
  const [key] = Object.entries(routes).find(
    ([, route]) => JSON.stringify(route) === defaultHomepageRouteString,
  ) ?? ["profile"];
  return key;
};

/** Checks if the user has permission to use a specific homepage setting */
export const isValidHomepagePreferenceForUser = (
  permissionDict: { [key: string]: boolean },
  homepageSetting: string,
) =>
  validHomepageRoutes[homepageSetting] &&
  multiPermissionsSatisfies(
    permissionDict,
    validHomepageRoutes[homepageSetting].requiredResourcePermissions,
  );

/** Gets all keys that the user has permission to use as a homepage setting */
export const getAllValidHomepagePreferencesForUser = (permissionDict: {
  [key: string]: boolean;
}) =>
  Object.keys(validHomepageRoutes).filter((key) =>
    isValidHomepagePreferenceForUser(permissionDict, key),
  );

/**
 * Create alias routes that forward to other pages
 *
 * If you are changing the name of a route, it is a good idea to put the old pathname as an alias for the new one in this dictionary
 * so that users visiting the page again at the previous route aren't sent to a blank page
 *
 * Key: Alias path name
 * Value: Route the alias should forward to
 */
export const routeAliases: StringDict<AppRoute> = {
  "/dashboard/econsult": routes.consult,
};

export default routes;
