import Typesense from "typesense";
import { Location, Service } from "@alethea-medical/aletheamd-types";
import { ServiceItem } from "../../../../db/Service";
import { config, fbFirestore } from "../../../../firebase";
import nullToUndefinedAndParseTimestamps from "src/utils/nullToUndefinedAndParseTimestamps";

const servicesSchemaCollectionName = `${config.projectId}.services`;
export const error_noTypesenseKey = new Error(
  "Typesense search API key not found",
);
export const error_noSearchText = new Error("No search text provided");
export const error_noSearchResults = new Error("No search results found");

interface SearchByStringProps {
  location: Location;
  typesenseServicesSearchApiKey: string | undefined;
  searchText: string;
  paginationParameters:
    | { pageNumber: number; documentsPerPage: number }
    | undefined;
  permissions: PermissionsObject[];
}

interface PermissionsObject {
  specialty: string;
  userHasPermission: Promise<boolean>;
}

export async function searchByString({
  location,
  typesenseServicesSearchApiKey,
  searchText,
  paginationParameters,
  permissions = [],
}: SearchByStringProps): Promise<ServiceItem[]> {
  if (
    typesenseServicesSearchApiKey === undefined ||
    typesenseServicesSearchApiKey === ""
  )
    return Promise.reject(error_noTypesenseKey);
  if (searchText === undefined || searchText === "")
    return Promise.reject(error_noSearchText);

  // Create Typesense client
  const cloudClient = new Typesense.Client({
    nodes: [
      {
        host: "yg5aj4dz7potcv23p-1.a1.typesense.net",
        port: 443,
        protocol: "https",
      },
    ],
    apiKey: typesenseServicesSearchApiKey,
    connectionTimeoutSeconds: 2,
  });

  //? TEMP: Get the Typesense search parameters from firebase
  const parameters = await getTypesenseSearchParameters(
    false,
    location.province === "AB",
    permissions,
  );

  parameters.sort_by = parameters.sort_by.replace("<user_city>", location.city);
  parameters.sort_by = parameters.sort_by.replace(
    "<user_province>",
    location.province,
  );

  //? Sorting & filtering via province & city, can access via location.province and location.city
  // Insert the city & province to the parameters (NOT doing for now)
  // parameters.sort_by = parameters.sort_by.replace("<user_province>", location.province)
  // parameters.sort_by = parameters.sort_by.replace("<user_city>", location.city)

  //* Make the search
  //? I am not sure why, but page & per_page parameters weren't working (even without the limit_hits)
  const searchParameters: {
    q: string;
    query_by: string;
    filter_by?: string;
    sort_by?: string;
    page?: number;
    per_page?: number;
    limit_hits?: number;
  } = {
    q: searchText,
    query_by: parameters.query_by,
    filter_by: parameters.filter_by,
    sort_by: parameters.sort_by,
    limit_hits: 50,
  };

  return cloudClient
    .collections(servicesSchemaCollectionName)
    .documents()
    .search(searchParameters)
    .then(function (searchResults) {
      if (!searchResults.hits || searchResults.hits.length == 0)
        return Promise.resolve([]);

      const results: ServiceItem[] = [];

      for (let i = 0; i < searchResults.hits.length; i++) {
        const hit = searchResults.hits[i];

        if (hit.document === undefined) continue;

        results.push({
          id: (hit.document as any).id, // it does...
          service: hit.document as Service.Service,
        });
      }

      //* SORTING RESULTS:
      //* rules: city > province > response time
      results.sort(prioritizeServices(location.city, location.province));

      nullToUndefinedAndParseTimestamps(results);

      return Promise.resolve(results);
    });
}

export async function searchBySpecialtySubsite(
  location: Location,
  typesenseServicesSearchApiKey: string,
  specialty: string,
  subsite: string,
  permissions: PermissionsObject[],
): Promise<ServiceItem[]> {
  if (
    typesenseServicesSearchApiKey === undefined ||
    typesenseServicesSearchApiKey === ""
  )
    return Promise.reject(error_noTypesenseKey);

  // Create Typesense client
  const cloudClient = new Typesense.Client({
    nodes: [
      {
        host: "yg5aj4dz7potcv23p-1.a1.typesense.net",
        port: 443,
        protocol: "https",
      },
    ],
    apiKey: typesenseServicesSearchApiKey,
    connectionTimeoutSeconds: 2,
  });

  //? TEMP: Get the Typesense search parameters from firebase
  const parameters = await getTypesenseSearchParameters(
    true,
    location.province === "AB",
    permissions,
  );

  // Replace the specialty & subsite
  parameters.filter_by = parameters.filter_by.replace("<specialty>", specialty);
  parameters.filter_by = parameters.filter_by.replace("<subsite>", subsite);

  //* Make the search
  //? I am not sure why, but page & per_page parameters weren't working (even without the limit_hits)
  const searchParameters: {
    q: string;
    query_by: string;
    filter_by?: string;
    sort_by?: string;
    page?: number;
    per_page?: number;
    limit_hits?: number;
  } = {
    q: "*", // to get everything, then filter by
    query_by: parameters.query_by,
    filter_by: parameters.filter_by,
    sort_by: parameters.sort_by,
    limit_hits: 50,
  };

  return cloudClient
    .collections(servicesSchemaCollectionName)
    .documents()
    .search(searchParameters)
    .then(function (searchResults) {
      if (!searchResults.hits || searchResults.hits.length == 0)
        return Promise.resolve([]);

      const results: ServiceItem[] = [];

      for (let i = 0; i < searchResults.hits.length; i++) {
        const hit = searchResults.hits[i];

        if (hit.document === undefined) continue;

        results.push({
          id: (hit.document as any).id, // it does...
          service: hit.document as Service.Service,
        });
      }

      //* SORTING RESULTS:
      //* rules: city > province > response time
      results.sort(prioritizeServices(location.city, location.province));

      nullToUndefinedAndParseTimestamps(results);

      return Promise.resolve(results);
    });
}

export async function getTypesenseSearchParameters(
  forDirectory: boolean,
  albertaDoctor: boolean,
  permissions: PermissionsObject[] = [],
) {
  // Get correct object from firebase
  const fieldName = forDirectory ? "directory" : "searchBar";
  const snapshot = await fbFirestore
    .collection("system")
    .doc("typesenseSearchParameters")
    .get();
  const typesenseSearchParameters = snapshot.data()?.[fieldName];

  //? Notes:
  //? - Don't need to use _eval() for boolean values, since you can only use one (eg. aletheaMember:desc works, no need to do _eval(aletheaMember:true):desc)
  //? - For sorting: combine via ', ' (eg. aletheaMember:desc, _eval(clinic.location.province:<user_province>):desc)
  //? - For filtering: combine via ' && ', or other logical operations (eg. enabled:true && aletheaMember:true)
  //? - The reason for `<user_province>` and `<user_city>` is because you cannot use variables in sorting/filtering. So we have to replace it with the user's location before querying
  //? - Use ":=" in filter_by for exact match
  //! To escape special characters, wrap strings in backticks (eg. specialty:=`<specialty>`)

  //* Default parameters (incase they don't exist in firebase)
  const defaultSearchBarParameters = {
    query_by:
      "serviceName, specialty, subsite, users.userName, keywords, clinic.clinicName",
    filter_by: "enabled:=true && aletheaMember:=true",
    sort_by: "_eval(clinic.location.city:`<user_city>`):desc", //? past iteration: '_eval(aletheaMember:true):desc, _eval(clinic.location.province:<user_province>):desc, _eval(clinic.location.city:<user_city>):desc'
  };

  const defaultDirectoryParameters = {
    query_by: "",
    filter_by:
      "enabled:=true && aletheaMember:=true && specialty:=`<specialty>` && subsite:=`<subsite>`",
    sort_by: "",
  };

  const defaultParameters = forDirectory
    ? defaultDirectoryParameters
    : defaultSearchBarParameters;
  //*

  const parameters = {
    query_by: "",
    filter_by: "",
    sort_by: "",
  };

  // Grab them from Firebase, if not, use the default ones defined here
  parameters.query_by =
    typesenseSearchParameters?.query_by ?? defaultParameters.query_by;
  parameters.filter_by =
    typesenseSearchParameters?.filter_by ?? defaultParameters.filter_by;
  parameters.sort_by =
    typesenseSearchParameters?.sort_by ?? defaultParameters.sort_by;

  // If doctor is in AB, they should not see any results from outside AB
  if (albertaDoctor) parameters.filter_by += ` && clinic.location.province:=AB`;

  // Hide specialties depending on the user's permissions
  // check if user has permission for the "other" specialty, stored first in permissions array
  const standardSpecialties = "All Standard Specialties";
  const boolStandardSpecialtyPermission = await permissions.find(
    (permission) => permission.specialty === standardSpecialties,
  )?.userHasPermission;
  let hasPermissionsForAnySpecialty = false;

  for (const permission of permissions) {
    if (boolStandardSpecialtyPermission) {
      // Can show standard specialties. Exclude other specialties if the user does not have permission
      hasPermissionsForAnySpecialty = true;
      if (
        permission.specialty !== standardSpecialties &&
        !(await permission.userHasPermission)
      ) {
        parameters.filter_by += ` && specialty:!=\`${permission.specialty}\``;
      }
    } else {
      // Cannot show standard specialties. Only show the user's specialties.
      if (
        permission.specialty !== standardSpecialties &&
        (await permission.userHasPermission)
      ) {
        hasPermissionsForAnySpecialty = true;
        parameters.filter_by += ` && specialty:==\`${permission.specialty}\``;
      }
    }
  }

  if (!hasPermissionsForAnySpecialty) {
    // filter everything from the search results. User has no permissions
    parameters.filter_by += ` && id:==-1`;
  }

  return parameters;
}

export function prioritizeServices(
  city: string,
  province: string,
): (a: ServiceItem, b: ServiceItem) => number {
  return (a, b) => {
    //Prioritize being in the same city first
    if (
      a.service.clinic.location.city.toLowerCase() === city.toLowerCase() &&
      b.service.clinic.location.city.toLowerCase() !== city.toLowerCase()
    )
      return -1;
    if (
      a.service.clinic.location.city.toLowerCase() !== city.toLowerCase() &&
      b.service.clinic.location.city.toLowerCase() === city.toLowerCase()
    )
      return 1;

    //Prioritize being in the same province second
    if (
      a.service.clinic.location.province.toLowerCase() ===
        province.toLowerCase() &&
      b.service.clinic.location.province.toLowerCase() !==
        province.toLowerCase()
    )
      return -1;
    if (
      a.service.clinic.location.province.toLowerCase() !==
        province.toLowerCase() &&
      b.service.clinic.location.province.toLowerCase() ===
        province.toLowerCase()
    )
      return 1;

    //Prioritize better average response time to consultations

    // (-1 means no response time data)
    if (
      a.service.econsultResponseTime === -1 &&
      b.service.econsultResponseTime !== -1
    )
      return 1;
    if (
      a.service.econsultResponseTime !== -1 &&
      b.service.econsultResponseTime === -1
    )
      return -1;

    if (a.service.econsultResponseTime < b.service.econsultResponseTime)
      return -1;
    if (a.service.econsultResponseTime > b.service.econsultResponseTime)
      return 1;
    if (a.service.econsultResponseTime === b.service.econsultResponseTime)
      return 0;
    else return 0;
  };
}
