import React from "react";
import clsx from "clsx";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    minWidth: "45px",
  },
  drawerButton: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(3),
    },
  },
  drawerButtonSelected: {
    backgroundColor: theme.palette.grey[200],
  },
}));

export interface AppRoute {
  path: string;
  name: string;
}

export interface AletheaSidebarItemProps {
  /** Route to navigate to (path and name) */
  route?: AppRoute;
  /** Label to show beside the icon */
  label?: string;
  /** Hover tooltip text */
  tooltip?: string;
  /** Class for the label text */
  textClass?: string;
  /** Show or hide label text */
  showText: boolean;
  /** Icon to show */
  icon: React.ReactElement;
  /** Current path. If route.path matches currentPath, then highlight this item */
  currentPath?: string;
  /** onClick listener */
  onClick?: () => void;
  /** Link component from react-router-dom */
  linkComponent?: any;
  /** Show this component in the list. Default true */
  enabled?: boolean;
  /** Class overrides */
  classes?: {
    icon?: string;
    drawerButton?: string;
    drawerButtonSelected?: string;
  };
  /** Additional custom class name */
  className?: string;
  /** Additional custom styles */
  style?: React.CSSProperties;
}

const AletheaSidebarItem: React.FC<AletheaSidebarItemProps> = ({
  route,
  label = route?.name ? route.name : "",
  tooltip = route?.name ? route.name : "",
  textClass,
  showText,
  icon,
  currentPath,
  onClick = () => {},
  linkComponent,
  enabled = true,
  classes: classOverrides,
  className,
  style,
  ...rest
}: AletheaSidebarItemProps) => {
  const classes = useStyles();

  return (
    <>
      {enabled && (
        <ListItem
          className={clsx(
            classOverrides?.drawerButton ?? classes.drawerButton,
            {
              [classOverrides?.drawerButtonSelected ??
              classes.drawerButtonSelected]:
                currentPath !== undefined && currentPath === route?.path,
            },
            className,
          )}
          button
          component={linkComponent}
          to={route?.path}
          onClick={onClick}
          style={style}
          {...rest}
        >
          <ListItemIcon className={classOverrides?.icon ?? classes.icon}>
            <Tooltip title={tooltip}>{icon}</Tooltip>
          </ListItemIcon>
          <Fade in={showText}>
            <ListItemText className={textClass} primary={label} />
          </Fade>
        </ListItem>
      )}
    </>
  );
};

export default AletheaSidebarItem;
