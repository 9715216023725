import { useContext, useState, useEffect } from "react";
import clsx from "clsx";
import { AuthContext } from "../../../../../AuthProvider";
import { ActivityItem } from "../../types";
import MessageListItem from "../../../../../components/MessageListItem";
import Grid from "@material-ui/core/Grid";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import palette from "../../../../../palette";
import {
  formatTimeDateThenTime,
  formatTimeShort,
} from "../../../../../models/formatTime";
import Typography from "@material-ui/core/Typography";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import { Activity } from "../../../../../../shared/types";
import ConsultStatus from "../../Toolbar/ConsultStatus";
import EconsultOutcomesLabel from "../../EconsultComponents/EconsultOutcomesLabel";
import usePermissions from "../../../../../components/usePermissions";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overdueThread: {
      background: palette.orangeLightLight,
    },
    overdueIcon: {
      marginRight: theme.spacing(2),
    },
    phoneText: {
      color: palette.orange,
      fontWeight: 600,
      fontSize: "1.0em",
    },
    labelContainer: {
      padding: theme.spacing(1, 1, 0, 0),
    },
    iconRead: {
      color: palette.darkGreen,
    },
    iconUnread: {
      color: palette.orange,
    },
    timestamp: {
      whiteSpace: "nowrap",
    },
  }),
);
interface ActivityListItemProps {
  activity: ActivityItem<Activity.UserActivity>;
  selected: boolean;
  setSelected: (id: string, checked: boolean) => void;
  openActivity: (id: string) => void;
}

const ActivityListItem = ({
  activity,
  openActivity,
  selected,
  setSelected,
}: ActivityListItemProps) => {
  const authContext = useContext(AuthContext);
  const classes = useStyles();

  const [read, setRead] = useState<boolean>(true);
  const [overdue, setOverdue] = useState<boolean>(false);

  useEffect(() => {
    if (authContext.uid === "") return;

    setRead(
      activity.sharedActivity.recentMessage.readBy.includes(authContext.uid),
    );
    setOverdue(activity.metadataActivity.overdue === true);
  }, [authContext.uid, activity]);

  const { granted: hasEditStatusPermissions } = usePermissions({
    resourceKey: resourceKeys.editActivityStatus,
  });

  const activityRole =
    activity.sharedActivity.profiles[authContext.uid]?.activityRole;

  return (
    <MessageListItem
      className={clsx({
        [classes.overdueThread]: overdue,
      })}
      selected={selected}
      setSelected={setSelected}
      openMessage={openActivity}
      id={activity.id}
      read={read}
      labelComponent={
        <div className={classes.labelContainer}>
          {activity.sharedActivity.activityType === "econsult" && (
            <QuestionAnswerIcon
              className={clsx({
                [classes.iconRead]: read,
                [classes.iconUnread]: !read,
              })}
            />
          )}
          {activity.sharedActivity.activityType === "phoneConsult" && (
            <PermPhoneMsgIcon
              className={clsx({
                [classes.iconRead]: read,
                [classes.iconUnread]: !read,
              })}
            />
          )}
        </div>
      }
      primary={
        <Grid container alignItems="center" spacing={1}>
          {activity.metadataActivity.status !== undefined &&
            hasEditStatusPermissions &&
            activityRole && (
              <Grid item>
                <ConsultStatus
                  status={activity.metadataActivity.status}
                  viewerRole={activityRole}
                />
              </Grid>
            )}
          <Grid item>{activity.sharedActivity.subject}</Grid>
        </Grid>
      }
      secondary={
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <EconsultOutcomesLabel
              specialistResponse={
                activity.sharedActivity.exposedEconsultData?.specialistResponse
              }
              fontSize="small"
            />
          </Grid>
          <Grid item>
            {activity.sharedActivity.activityType === "phoneConsult" &&
              activity.sharedActivity.phoneConsultData !== undefined &&
              !activity.sharedActivity.phoneConsultData.callComplete && (
                <Typography className={classes.phoneText}>
                  Call scheduled for{" "}
                  {formatTimeDateThenTime(
                    activity.sharedActivity.phoneConsultData?.startTime.toDate(),
                  )}
                </Typography>
              )}
          </Grid>
        </Grid>
      }
    >
      <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Typography className={classes.timestamp}>
            {" "}
            {formatTimeShort(
              activity.sharedActivity.recentMessage.sentAt.toDate(),
            )}
          </Typography>
        </Grid>
        <Grid item>{overdue && <AccessTimeIcon />}</Grid>
      </Grid>
    </MessageListItem>
  );
};

export default ActivityListItem;
