import firebase from "firebase";
import { fbFirestore } from "../../../../../firebase";
import { hexEncoding } from "@alethea-medical/utilities";


class InboxModel {
    metadataRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    sharedRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    orderByField: string

    constructor(metadataRefId: string, sharedCollection: string, metadataCollection: string, metadataSubCollection: string, orderByField: string) {
        this.metadataRef = fbFirestore.collection(metadataCollection).doc(metadataRefId).collection(metadataSubCollection)
        this.sharedRef = fbFirestore.collection(sharedCollection)
        this.orderByField = orderByField
    }

    loadActivities (fetchEarlierThan: firebase.firestore.Timestamp, options?: {
        amountToLoad?: number,
        folder?: string,
        statuses?: string[],
        fetchOverdue?: boolean
    }): Promise<{ results: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[], didReturnResults: boolean }> {
        let query = this.metadataRef.where(this.orderByField, "<", fetchEarlierThan)
        if(options?.statuses !== undefined && options?.folder !== undefined)
            return Promise.reject(new Error("Cannot filter by both status and folder."))

        if(options?.folder !== undefined)
            query = query.where("folder", "==", options.folder)
        if(options?.statuses !== undefined)
            query = query.where("status", "in", options.statuses)
    
        query = query.orderBy(this.orderByField, "desc")
        if(options?.amountToLoad !== undefined)
            query = query.limit(options.amountToLoad)

        return query.get()
        .then((snapshot) => {
            if(options?.fetchOverdue) {
                return this.loadOverdueActivities(options?.folder)
                .then((snapshotOverdue) => {
                    return snapshotOverdue.docs.concat(snapshot.docs)
                })
            }
            else {
                return snapshot.docs
            }
        })
        .then((docs) => {
            return {
                results: docs,
                didReturnResults: docs.length > 0
            }
        })
    }

    loadOverdueActivities(folder: string | undefined): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>{
        let query = this.metadataRef.where("overdue", "==", true)
        if(folder !== undefined)
            query = query.where("folder", "==", folder)
        return query.get()
    }

    searchActivities (params: string[], status: string, fetchEarlierThan: firebase.firestore.Timestamp, amountToLoad: number): Promise<{ results: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[], didReturnResults: boolean }>{
        if(params.length === 0 && status === "")
            return Promise.reject(new Error("Cannot search with no search terms or statuses."))
        const searchTerms = this.createSearchTerms(params)
    
        if(searchTerms.length > 10)
            return Promise.reject(new Error("Cannot use more than 10 search terms. Try removing some and try again."))


        let query = this.metadataRef.orderBy(this.orderByField, "desc").startAfter(fetchEarlierThan)
        if(status !== "")   {
            query = query.where("status", "==", status)
        }

        if(searchTerms.length > 0) {
            query = (query === undefined ? this.metadataRef : query).where("keywords", "array-contains-any", searchTerms)
        }            
        return query.limit(amountToLoad).get()
        .then((snapshot) => {
            return {
                results: snapshot.docs.filter((doc) => {
                    const data = doc.data() as { keywords?: string[], status?: string }

                    // Make sure every keyword exists in the activity's keywords since array-contains-any will return any results that have at least one keyword
                    return searchTerms.every((p) => 
                        data.keywords !== undefined && data.keywords.indexOf(p) !== -1
                    )
                }),
                didReturnResults: snapshot.docs.length > 0 // If query returned anything (even if filtered out), then return true
            }
        })
    }

    subscribeToActivityMetadata (mostRecentFetchTime: firebase.firestore.Timestamp, 
        newUserActivitiesSnapshotHandler: (docs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]) => void, 
        options?: { folder?: string }): () => void {

        let query = this.metadataRef.where(this.orderByField, ">", mostRecentFetchTime)
        if(options?.folder !== undefined)
            query = query.where("folder", "==", options.folder)

        return query.orderBy(this.orderByField, "desc").onSnapshot((snapshot) => {
            newUserActivitiesSnapshotHandler(snapshot.docs)
        });
    }

    createSearchTerms(params: string[]): string[] {
        return params.map((p) => {
            // Remove dash from PHN
            if(/\d{5}-.*/.test(p))
                p = p.split('-').join('')
            return hexEncoding.encodeStringToHex(p.toLowerCase())
        })
    }
}


export default InboxModel