import Tooltip from "@material-ui/core/Tooltip";
import Button from '@material-ui/core/Button';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Collapse from '@material-ui/core/Collapse';

import UnarchiveIcon from '@material-ui/icons/Unarchive';
import ArchiveIcon from '@material-ui/icons/Archive';

import { Activity } from "../../../../../shared/types";


interface SecureMessagingToolbarProps {
    folder: Activity.UserActivityFolder,
    atLeastOneSelected: boolean,
    markAsUnread: () => void,
    moveFolder: (folder: Activity.UserActivityFolder) => void,
}

const SecureMessagingToolbar = ({ folder, atLeastOneSelected, markAsUnread, moveFolder }: SecureMessagingToolbarProps) => {

    return (
        <>
            <Collapse in={atLeastOneSelected} unmountOnExit>
                <ButtonGroup>
                    <Tooltip
                        title="Mark as Unread"
                        children={
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={markAsUnread}
                                startIcon={<MarkunreadMailboxIcon/>}
                            >
                                Unread
                            </Button>      
                        }
                    />      
                    <Tooltip
                        title={folder === "inbox" ? "Move to Archive" : "Move to Inbox"}
                        children={
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => {
                                    moveFolder(folder === "inbox" ? "archive" : "inbox")
                                }}
                                startIcon={folder === "inbox" ? <ArchiveIcon/> : <UnarchiveIcon/>}
                            >
                                {folder === "inbox" ? "Archive" : "Unarchive"}
                            </Button> 
                        }
                    />
                </ButtonGroup>  
            </Collapse>

        </>
    );
}

export default SecureMessagingToolbar;