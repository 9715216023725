import { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { SearchOption, SearchParam } from "./SearchBar";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Typography from '@material-ui/core/Typography';



interface SearchItemProps {
    index: number,
    searchParam: SearchParam,
    searchOptions: SearchOption[],
    setSearchField: (field: string, index: number) => void,
    setSearchValue: (value: string, index: number) => void,
    removeItem: (index: number) => void,
}

const SearchItem = ({ index, searchParam, searchOptions, setSearchField, setSearchValue, removeItem }: SearchItemProps) => {

    const [internalValue, setInternalValue] = useState(searchParam.value);
    const [selectedOption, setSelectedOption] = useState<SearchOption>();
    
    useEffect(() => {
        const newSelectedOption = searchOptions.find((o) => {return o.field === searchParam.field});
        if(newSelectedOption !== undefined)
            setSelectedOption(newSelectedOption)
    }, [searchParam])

    const getDisplayName = (text: string) => {
        const result = text.replace( /([A-Z])/g, " $1" );
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    const updateValue = () => {
        setSearchValue(internalValue, index);
    }


    return (
           <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <Button startIcon={<RemoveCircleOutlineIcon/>} color="primary" onClick={() => {removeItem(index)}}/>
                </Grid>
                <Grid item xs={5} md={3} lg={2}>
                    {searchOptions.length > 1 && 
                        <Select
                            value={searchParam.field}
                            onChange={(e) => {
                                setSearchField(e.target.value as string, index);
                                setInternalValue("");
                            }}
                            fullWidth
                        >
                            {searchOptions.map((option) => 
                                <MenuItem key={`field_${option.field}_${index}`} value={option.field}>{option.display ? option.display : getDisplayName(option.field)}</MenuItem>
                            )}
                        </Select>
                    }
                    {searchOptions.length === 1 &&
                        <Typography>{searchOptions[0].display ? searchOptions[0].display : getDisplayName(searchOptions[0].field)}</Typography>
                    }
                </Grid>

                <Grid item xs={true}>
                    {selectedOption?.options !== undefined ? (
                        <Select
                            value={searchParam.value}
                            onChange={(e) => {
                                setInternalValue(e.target.value as string);
                                setSearchValue(e.target.value as string, index);
                            }}
                            fullWidth
                        >
                            {selectedOption.options.map((option) => 
                                <MenuItem key={`value_${option.value}_${index}`} value={option.value}>{option.display ? option.display : getDisplayName(option.value)}</MenuItem>
                            )}
                        </Select>
                    ) : (
                        <TextField 
                            value={internalValue}
                            onChange={(e) => {
                                setInternalValue(e.target.value as string);
                            }}
                            onKeyPress={(e) => {
                                if(e.key === "Enter") {
                                    updateValue();
                                }
                            }}
                            onBlur={updateValue}
                            fullWidth
                        />
                    )}
                </Grid>
            </Grid>
    );
}

export default SearchItem;