import { SignupInfo, Role } from '@alethea-medical/aletheamd-types';
import { Control, UseFormTrigger } from 'react-hook-form';
import { TypedEmailInput } from '../../../../../components/FormInputFields/EmailInput';
import { TypedFormTextField } from '../../../../../components/FormInputFields/FormTextField';
import { TypedPhoneInput } from '../../../../../components/FormInputFields/PhoneInput';
import FormattedInputLabel from '../../../../../components/FormattedInputLabel';
import Grid from '@material-ui/core/Grid';
import strings from '../../Common/strings';
import PasswordInput from '../../Helpers/Input/PasswordInput';
import PracIdValidation from '../../Helpers/Input/PracIdValidation';
import HelpModal from '../../../../../components/HelpModal';
import SectionContainer from '../../Helpers/Container/SectionContainer';

interface DoctorInformationProps {
    control: Control<SignupInfo.SignupInfo>,
    trigger: UseFormTrigger<SignupInfo.SignupInfo>,
    isPracIdValid: boolean,
    setIsPracIdValid: (value: boolean) => void,
    proceedWithoutPracId: boolean,
    setProceedWithoutPracId: (value: boolean) => void,
    disabled: boolean,
    disabledNeedPracId: boolean,
    disablePracIdEntry?: boolean,
    role: Role.Role,
    number: number,
}

const DoctorInformation = ({
    control,
    trigger,
    isPracIdValid,
    setIsPracIdValid,
    proceedWithoutPracId,
    setProceedWithoutPracId,
    disabled,
    disabledNeedPracId,
    disablePracIdEntry,
    role,
    number
}: DoctorInformationProps) => {

    return (
        <SectionContainer title={strings.informationSectionHeader(role)} number={number}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                    <FormattedInputLabel>First Name</FormattedInputLabel>
                    <TypedFormTextField
                        control={control}
                        name="firstName"
                        rules={{ required: { value: true, message: "First name is required." } }}
                        disabled={disabled}
                        errorTextPadding
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormattedInputLabel>Last Name</FormattedInputLabel>
                    <TypedFormTextField
                        control={control}
                        name="lastName"
                        rules={{ required: { value: true, message: "Last name is required." } }}
                        disabled={disabled}
                        errorTextPadding
                    />
                </Grid>
                <Grid item xs={12} md>
                    <FormattedInputLabel>Cell Phone Number</FormattedInputLabel>
                    <TypedPhoneInput
                        control={control}
                        name="cellPhone"
                        rules={{ required: { value: true, message: "Cell phone is required." } }}
                        disabled={disabled}
                        errorTextPadding
                    />
                </Grid>
                <Grid item xs={11} md>
                    <FormattedInputLabel>Email</FormattedInputLabel>
                    <TypedEmailInput
                        control={control}
                        name="email"
                        rules={{ required: { value: true, message: "Email is required." } }}
                        disabled={disabled}
                        errorTextPadding
                    />
                </Grid>
                <Grid item container xs={1}>
                    <HelpModal helpText={[strings.loginInformationNote]} />
                </Grid>
                {!Role.hasClinic(role) &&
                    <Grid item xs={12} md={3}>
                        <FormattedInputLabel>City</FormattedInputLabel>
                        <TypedFormTextField
                            control={control}
                            name="city"
                            rules={{ required: { value: true, message: "City is required." } }}
                            errorTextPadding
                            disabled={disabled}
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <PracIdValidation
                        control={control}
                        isPracIdValid={isPracIdValid}
                        setIsPracIdValid={setIsPracIdValid}
                        proceedWithoutPracId={proceedWithoutPracId}
                        setProceedWithoutPracId={setProceedWithoutPracId}
                        disabled={disabled || (disablePracIdEntry !== undefined && disablePracIdEntry)}
                    />
                </Grid>
                <PasswordInput control={control} trigger={trigger} disabled={disabled || disabledNeedPracId} />
            </Grid>
        </SectionContainer>
    );
}

export default DoctorInformation;