import Grid from "@material-ui/core/Grid";
import {
  Control,
  UseFormTrigger,
  UseFormWatch,
  UseFormSetValue,
} from "react-hook-form";
import { Activity, PatientInfo } from "../../../../../shared/types";
import { TypedFormDateTextField } from "../../../../components/FormInputFields/FormDateTextField";
import { TypedFormTextField } from "../../../../components/FormInputFields/FormTextField";
import { TypedPhoneInput } from "../../../../components/FormInputFields/PhoneInput";
import { ConsultEmail } from "./ConsultEmail";
import { ConsultEmailPatientCheckbox } from "./ConsultEmailPatientCheckbox";
import { ConsultPHN } from "./ConsultPHN";
import ConsultPatientProvinceDropdown from "./ConsultPatientProvinceDropdown";
import ConsultOOPInput from "./ConsultOOPInput";
import { useEffect } from "react";

interface ConsultPatientInfoFormProps {
  control: Control<Activity.ConsultFormFields>;
  trigger: UseFormTrigger<Activity.ConsultFormFields>;
  watch: UseFormWatch<Activity.ConsultFormFields>;
  setValue: UseFormSetValue<Activity.ConsultFormFields>;
  disabled: boolean;
  onSelectPatient: (patientInfo: PatientInfo) => void;
  serviceProvince?: string;
}

function calculateAge(dob: string) {
  const birthday = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - birthday.getFullYear();
  const m = today.getMonth() - birthday.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
    age--;
  }
  return age;
}

const ConsultPatientInfoForm = ({
  control,
  trigger,
  watch,
  setValue,
  disabled,
  onSelectPatient,
  serviceProvince,
}: ConsultPatientInfoFormProps) => {
  const dateOfBirth = watch("patientInfo.dateOfBirth");

  // Update Age when date of birth changes
  useEffect(() => {
    const age = calculateAge(dateOfBirth);

    if (dateOfBirth === "") setValue("patientInfo.age", "");
    else if (!isNaN(age) && age >= 0)
      setValue("patientInfo.age", age.toString());
    else setValue("patientInfo.age", "Invalid Date of Birth");
  }, [dateOfBirth, setValue]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={4} sm={2}>
        <ConsultPatientProvinceDropdown
          control={control}
          disabled={disabled}
          serviceProvince={serviceProvince}
        />
      </Grid>
      <Grid item xs={12} md={10}>
        <ConsultPHN
          control={control}
          trigger={trigger}
          disabled={disabled}
          onSelectPatient={onSelectPatient}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TypedFormTextField
          name="patientInfo.firstName"
          control={control}
          label="Patient First Name"
          rules={{
            required: {
              value: true,
              message: "Patient first name is required",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TypedFormTextField
          name="patientInfo.lastName"
          control={control}
          label="Patient Last Name"
          rules={{
            required: { value: true, message: "Patient last name is required" },
          }}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ConsultEmail control={control} trigger={trigger} disabled={disabled} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ConsultEmailPatientCheckbox control={control} disabled={disabled} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TypedPhoneInput
          name="patientInfo.phone"
          control={control}
          label="Patient Phone Number"
        />
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12} sm={3}>
          <TypedFormDateTextField
            name="patientInfo.dateOfBirth"
            control={control}
            label="Patient Date of Birth"
            rules={{
              required: {
                value: true,
                message: "Patient date of birth is required",
              },
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TypedFormTextField
            name="patientInfo.age"
            control={control}
            label="Patient Age"
            disabled={true}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ConsultOOPInput control={control} disabled={disabled} />
      </Grid>
    </Grid>
  );
};

export default ConsultPatientInfoForm;
export { calculateAge };
